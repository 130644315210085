import React, { useState } from "react";
import moment from "moment";
import baseApi from "../../Api/config";
import ResourceImage from "../../Assets/Images/Groups/Resources.png";

const ResourceComponent = ({ allResourceData }) => {
    const [activeTab, setActiveTab] = useState("pdf"); // "pdf" or "image"

    // Filter data
    const pdfData = allResourceData.filter((val) => val.type === "pdf");
    const imageData = allResourceData.filter((val) => val.type !== "pdf");

    return (
        <div className="flex flex-col justify-start p-4 w-full" style={{
            background: `url(${ResourceImage})`,
            backgroundSize: 'contain',
            backgroundPosition: 'right bottom',
            backgroundRepeat: 'no-repeat',
        }}>
            <div className="flex justify-start items-center mb-4">
                <button
                    className={`px-8 py-2 rounded-full text-bold mr-4 ${activeTab === "pdf"
                            ? "bg-[#ABDC8C] text-white border border-[#ABDC8C]"
                            : "bg-white text-gray-900 border border-[#D8DEE4]"
                        }`}
                    onClick={() => setActiveTab("pdf")}
                >
                    Documents
                </button>
                <button
                    className={`px-8 py-2 rounded-full text-bold mr-4 ${activeTab === "image"
                            ? "bg-[#ABDC8C] text-white border border-[#ABDC8C]"
                            : "bg-white text-gray-900 border border-[#D8DEE4]"
                        }`}
                    onClick={() => setActiveTab("image")}
                >
                    Images
                </button>
            </div>

            {/* Content */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 px-4">
                {(activeTab === "pdf" ? pdfData : imageData).length === 0 ? (
                    <span className="text-gray-500">Data not found</span>
                ) : (
                    (activeTab === "pdf" ? pdfData : imageData).map((val) => (
                        <div
                            className="bg-white border rounded shadow p-4"
                            key={val._id}
                        >
                            <a
                                href={`${baseApi.baseurl}images/${val?.mediafile}`}
                                target="_blank"
                                rel="noreferrer"
                                className="block"
                            >
                                <div className="flex flex-col items-center">
                                    <div className="mb-2">
                                        <span
                                            className={`${val?.type === "pdf"
                                                    ? "text-red-500"
                                                    : "text-blue-500"
                                                } text-xl`}
                                        >
                                            {val?.type === "pdf" ? "📄" : "🖼️"}
                                        </span>
                                    </div>
                                    <p className="text-sm text-gray-700">Media Type: {val?.type}</p>
                                    <p className="text-sm font-semibold text-gray-800">
                                        {val?.originalfilename}
                                    </p>
                                    <p className="text-sm text-gray-500">
                                        {moment(val?.createdAt).format("LL")}
                                    </p>
                                </div>
                            </a>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default ResourceComponent;
