import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../../../Api/Api";
import AdminRoute from "../../../Route/RouteDetails";
import useRazorpay from "react-razorpay";

const MakePayment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { FeatureName } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const planID = JSON.parse(queryParams.get("planID"));
  const amount = atob(JSON.parse(queryParams.get("amount")));
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
  const [Razorpay] = useRazorpay();

  const MakePayment = () => {
    // get rezorpay orderid id
    API.Payment.RezorPayGetId({
      data: {
        name: UserData?.userdata?.name,
        created_by: UserData?.userdata?._id,
        amount: amount,
      },
    })
      .then((response) => {
        if (response?.data?.message === "successfully") {
          RezorPaySdk(response?.data?.data, response?.data?.key_id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const RezorPaySdk = (orderID, appKey) => {
    const options = {
      // key: "rzp_test_nYt1RNLg2H6FzV", // Enter the Key ID generated from the Dashboard
      //   key: "rzp_live_8o7ruw18zkHvQj", // Prod Live key
      key: appKey,
      amount: orderID?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: orderID?.currency,
      name: "RYT",
      description: orderID?.notes?.description
        ? orderID?.notes?.description
        : "Test",
      image: "https://example.com/your_logo",
      order_id: orderID.id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
        console.log("sucess");
        handleRezorpayResponce(response, orderID.id, orderID.amount, "finish");
        // handlePlanSave(plan_id)
      },
      prefill: {
        name: UserData?.userdata?.name,
        email: UserData?.userdata?.email,
        contact: UserData?.userdata?.phone,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      console.log("faile");
      handleRezorpayResponce(response, orderID.id, orderID.amount, "decline");
    });
    rzp1.open();
  };

  const handleRezorpayResponce = (response, orderID, amount, status) => {
    API.Payment.Paymentupdate({
      data: {
        order_id: orderID,
        name: UserData?.userdata?.name,
        created_by: UserData?.userdata?._id,
        amount: Math?.floor(amount / 100),
        responce_obj: response,
        order_status: status,
        paymentfeatures: FeatureName,
        featureid: planID,
      },
    })
      .then((response) => {
        if (response.data.status === 1) {
          setTimeout(() => {
            navigate(`../${AdminRoute.Common.Dashboard}`);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="make-payment">
      <div className="payment-box-outer">
        {/* <p className="px-4">
          Help us serve you better by selecting a plan that suits you best
        </p> */}
        <NavLink to="/">
          <p className="payment-back position-absolute text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
              />
            </svg>
          </p>
        </NavLink>

        <div className="payment-box mx-auto p-2">
          <h1 className="heading payment-amount text-center text-white mt-0">
            INR. {amount ? amount : "XXX"}/-
          </h1>
          <h6 className="fw-bold px-2">Features</h6>
          <p className="px-2">RYT Mind</p>
          <p className="px-2">
          Stay continuously connected with our AI wellness coach for personalised, anytime(24*7) mental health support. Communicate by interacting with voice or text, speak in a language you’re comfortable with, and select between two dedicated mental health coaches, Adam or Eve.
          </p>
          {/* <p className="px-2">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.{" "}
          </p> */}

          <Button
            className="button_common m-0 px-4 w-100"
            onClick={() => MakePayment()}
          >
            Make Payment
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MakePayment;
