import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../Common/Sidebar";
import Header from "../../Components/Common/Header";
import Footer from "../../Components/Common/Footer";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import { formatDateTime, getInitialsLetters } from "../Common/Functions";
import API from "../../Api/Api";
import PlayGround from "../../Assets/Images/playground.jpg";
import moment from "moment";
import baseApi from "../../Api/config";
import { socket } from "../../socket";
import blankPageBG from "../../Assets/Images/DigitalNotes/NewNotes/newnotesbg.png";
import AdminRoute from "../../Route/RouteDetails";
import { useNavigate } from "react-router-dom";
import { LuSendHorizonal } from "react-icons/lu";

const MyTherapist = () => {

  const tabs = [
    { name: 'Documents', value: 0 },
    { name: 'Images', value: 1 },
  ];
  const navigate = useNavigate();
  const [room] = useState("messageroom");
  const [currMessage, setCurrMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [rows, setRows] = useState(1);
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
  const Therapistid = localStorage.getItem("TherapistID");
  const [therapistData, setTherapishData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingSubmitAns, setLoadingSubmitAns] = useState(false);
  const [allActivityData, setAllActivityData] = useState([]);
  const [allMediaData, setAllMediaData] = useState([]);
  const [activeTab, setActiveTab] = useState(tabs[0]?.value);

  const chatHistoryRef = useRef(null);

  useEffect(() => {
    getAllChats();
    // getTherapistDetails();
    getAllEventData();
    getAllMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const getTherapistDetails = () => {
  //   setLoading(true);
  //   API.Therapist.getTherpistDetails({
  //     date: moment(new Date()).format("YYYY-MM-DD"),
  //   })
  //     .then((response) => {
  //       if (response.data.status === 1) {
  //         setTherapishData(response?.data.data_all[0]);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //     });
  // };

  const getAllChats = (val) => {
    // setLoadingChat(true)
    API.MyThreapist.ChatList()
      .then((response) => {
        if (response.data.status === 1) {
          setMessageList(response.data.data_all);
          // setLoadingChat(false)
        }
      })
      .catch((error) => {
        console.log(error);
        // setLoadingChat(false)
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      setRows(1);
      sendMessage(e);
      e.preventDefault();
    } else if (e.key === "Enter" && e.shiftKey) {
      if (rows < 6) {
        setRows(rows + 1);
      }
      const cursorPosition = e.target.selectionStart;
      const newInputValue =
        currMessage.substring(0, cursorPosition) +
        "\n" +
        currMessage.substring(cursorPosition);
      setCurrMessage(newInputValue);
      e.preventDefault();
    }
  };

  //socket .io
  const sendMessage = async () => {
    if (currMessage !== "") {
      const messageData = {
        author: `${UserData?.userdata?.name} ${UserData?.userdata?.last_name}`,
        created_id: UserData?.userdata?._id,
        receiver_id: Therapistid
          ? Therapistid
          : UserData?.data_theropist[0]?.theropistId,
        message: currMessage,
        type: "all",
        time: new Date(),
      };
      await socket.emit(room, messageData);
    }
    setCurrMessage("");
  };

  useEffect(() => {
    if (!socket) return;

    const handleData = (data) => {
      setMessageList((list) => [...list, data]);
    };

    socket.on("messageroom", handleData);

    return () => {
      socket.off("messageroom", handleData);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, room]);

  const getAllMedia = () => {
    API.MyThreapist.meadialistindividual({
      id: UserData?.userdata?._id,
    })
      .then((response) => {
        if (response.data.status === 1) {
          setAllMediaData(response?.data?.data_all);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllEventData = () => {
    API.MyThreapist.eventlistindividual()
      .then((response) => {
        if (response.data.status === 1) {
          setAllActivityData(response?.data?.data_all);
        }
      })
      .catch((error) => {
        // setLoadingChat(false)
      });
  };

  const handleSubmitAns = (val, option) => {
    if (!loadingSubmitAns) {
      setLoadingSubmitAns(true);
      const requestData = {
        event_id: val._id,
        created_by: UserData?.userdata?._id,
      };
      requestData[option] = true;
      API.Groups.AnswerEvent({
        data: requestData,
      })
        .then((response) => {
          if (response.data.status === 1) {
            setLoadingSubmitAns(false);
            getAllEventData();
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingSubmitAns(false);
        });
    }
  };

  const handleDownload = async (imageUrl) => {
    let extension = imageUrl.split(".").pop();
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `image.${extension}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messageList]);

  return (
    <>
      {/* <div className="d-flex h-dashboard playground">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list dashboard therapist">
            <div className="row">
              <nav className="navbar navbar-expand-lg header-navbar p-0">
                <div className="w-100 h-head">
                  <h2 className="heading p-0">My Therapist</h2>
                </div>
                <Header />
              </nav>
            </div>

            <div className="col-lg-9 col-md-7 col-sm-7 mb-2 list-item">
              <div className="dashboard-inner mb-0 h-100">
                <div className="col-md-12  mb-2 list-item">
                  <div className="sessions-tabs">
                    <Tabs
                      defaultActiveKey="home"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                     
                      <Tab
                        eventKey="resource"
                        title="Activity"
                        className="tab-content"
                      >
                        <div className="d-flex chat justify-content-around resource">
                          <div className="chat">
                            <div className="chat-history">
                              
                              <div className="row row-flex row-flex-wrap px-4">
                                {allMediaData.length === 0 ? (
                                  <span>Data not found </span>
                                ) : (
                                  allMediaData?.map((val) => (
                                    <div
                                      className="col-xl-3 col-lg-6 col-md-6 mb-3"
                                      key={val._id}
                                    >
                                       <a
                                            href={`${baseApi.baseurl}images/${val?.mediafile}`}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                      <div className="panel panel-default flex-col h-100">
                                        <div className="drop_box">
                                         
                                            <div
                                              className="d-flex pdf-div cursor_pointer"
                                              
                                            >
                                              <span
                                                className={
                                                  val?.type === "pdf"
                                                    ? "pdf-icon"
                                                    : "media-icon"
                                                }
                                              ></span>
                                              <span className="pdf-text">
                                                {" "}
                                                Download
                                              </span>
                                            </div>

                                          
                                          <p>Media Type: {val?.type}</p>
                                          <p>{val?.originalfilename}</p>
                                          <p>
                                            {moment(val?.createdAt).format(
                                              "LL"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      </a>

                                    </div>
                                  ))
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>

                      <Tab eventKey="home" title="Messages">
                        <div className="d-flex justify-content-around">
                          <div className="chat">
                            <div className="chat-history" ref={chatHistoryRef}>
                              
                              <ul>
                                
                                {messageList &&
                                  messageList?.map((messageContent) => {
                                    return (
                                      <li
                                        key={messageContent?._id}
                                        className={
                                          UserData?.userdata?._id ===
                                          messageContent?.created_id
                                            ? "send-message "
                                            : "receive-message"
                                        }
                                      >
                                        <div className="message other-message">
                                          <h6 className="d-flex align-items-center">
                                            <span className="user-icon-avatar rounded-circle text-center mb-0 me-2 text-white">
                                              {UserData?.userdata?._id ===
                                              messageContent.created_id
                                                ? getInitialsLetters(
                                                    messageContent
                                                      .created_user[0].name,
                                                    messageContent
                                                      ?.created_user[0]
                                                      ?.last_name
                                                  )?.toUpperCase()
                                                : getInitialsLetters(
                                                    messageContent?.created_user[0]?.fullname?.split(
                                                      " "
                                                    )[0],
                                                    messageContent?.created_user[0]?.fullname?.split(
                                                      " "
                                                    )[1]
                                                  )?.toUpperCase()}
                                            </span>
                                            <span className="ml-piv">
                                              {UserData?.userdata?._id !==
                                              messageContent?.created_id
                                                ? messageContent?.created_user &&
                                                  messageContent
                                                    ?.created_user[0]?.fullname
                                                : `${UserData?.userdata?.name} ${UserData?.userdata?.last_name}`}
                                            </span>
                                          </h6>
                                          <p>{messageContent?.message}</p>
                                          <div className="message-data-time message-data align-right">
                                            {messageContent?.createdAt
                                              ? formatDateTime(
                                                  messageContent?.createdAt
                                                )
                                              : formatDateTime(new Date())}
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                            <div className="send-box">
                              <textarea
                                className="form-control bg-pink"
                                placeholder="Write a message..."
                                tabIndex="0"
                                cols={50}
                                autoComplete="off"
                                value={currMessage}
                                onChange={(event) =>
                                  setCurrMessage(event.target.value)
                                }
                                onKeyDown={handleKeyPress}
                              />
                              <div className="send-box-icon">
                                <Button
                                  className="btn btn--send"
                                  title="Send message"
                                  tabIndex="0"
                                  onClick={sendMessage}
                                >
                                  <svg
                                    className="btn__icon"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <line x1="22" y1="2" x2="11" y2="13"></line>
                                    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                  </svg>
                                </Button>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-5 col-sm-5 mb-2 list-item">
              <div className="dashboard-inner p-0 mb-0">
                <h3 className="secheading text-white text-center therapist-profile mt-0 mx-0">
                  My Therapist's Profile
                </h3>
                {loading ? (
                  <div className="container_loader"></div>
                ) : (
                  <div className="therapist-profile-text-div px-3">
                    <p className="therapist-profile-img mx-auto">
                      <img
                        src={
                          therapistData?.image
                            ? therapistData?.image
                            : PlayGround
                        }
                        alt="PlayGround"
                      />
                    </p>
                    <h3 className="secheading text-center mb-3">
                      {therapistData?.fullname}
                    </h3>
                    <h6 className="text-center fw-normal mb-3">
                      Years of Experience: {therapistData?.experience} Years
                    </h6>
                    <p className="h6 fst-italic text-center mb-3">
                      {therapistData?.qote}
                    </p>

                    <p className="fw-bold">Expertise</p>
                    <p>{therapistData?.experties}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div> */}
      <div className="flex flex-col bg-white mx-0 w-full h-full">
        <div className="flex-grow bg-white m-0 w-full p-0">
          <Header />
          <Sidebar />
          <div className="flex-grow flex flex-col bg-white md:flex-col lg:flex-row md:ml-[8%] h-full md:mr-[4%] md:my-8">
            <div
              className="flex flex-col flex-grow w-full justify-center items-center rounded-3xl z-1 bg-[#F5F6FB80] border border-[#E7F0FC]"
            >
              <div className="flex justify-end w-full mt-2 pr-[4%]">
                <button className="mt-2 h-8 bg-[#F5BD41] text-black py-2 px-4 rounded-lg shadow-md font-semibold align-middle text-center text-xs" onClick={() => navigate(`../${AdminRoute?.Common?.Dashboard}`)} >
                  Back
                </button>
              </div>
              <div className="flex mt-2 w-full">
                <div className="w-3/5 flex flex-col">
                  <div className="flex justify-center items-center py-2 w-full text-[#000000D4] text-2xl font-bold border-b border-[#D8D8D8]">
                    Messages
                  </div>
                  <div className="py-2 px-4 min-h-[65vh]">
                    <div className="flex flex-col bg-gradient-to-b from-[#F6EFE680] to-[#F0F4FB] rounded-3xl min-h-[62vh] border border-[#0000002E]">
                      <div class="relative flex items-center justify-center w-full border-b border-[#C7C6C6] pb-2">
                        <div class="absolute left-4 top-0 flex items-center">
                          <div class="w-12 h-12 rounded-full bg-purple-700 flex justify-center items-center">
                            <span class="text-white font-bold text-xl">S</span>
                          </div>
                          <div class="ml-4 mt-2">
                            <h1 class="font-bold text-lg mb-0">Smirthi</h1>
                            <p class="text-gray-500 text-sm">Therapist</p>
                          </div>
                        </div>
                        <h2 class="font-bold text-lg mt-4">1 - 1 Session Chats</h2>
                      </div>
                      <ul className="flex-grow min-h-[43vh] max-h-[43vh] overflow-y-auto overflow-x-hidden scrollbar-hide p-4" ref={chatHistoryRef}>
                        {messageList &&
                          messageList?.map((messageContent, i) => {
                            return (
                              <li
                                key={messageContent?._id}
                                className={`w-full flex mb-2 ${UserData?.userdata?._id === messageContent.user_id
                                  ? "justify-start"
                                  : "justify-end"
                                  }`}
                              >
                                <div className="flex flex-col">
                                  <div className={` border-1 border-[#C7C6C6] rounded-full pt-3 px-4 mb-1 lg:text-sm leading-relaxed relative text-left w-fit ${UserData?.userdata?._id === messageContent.user_id ? "rounded-bl-none bg-[#E8ECEF] text-gray-950 " : "rounded-br-none bg-[#72808B] text-white"} `}>
                                    {/* {UserData?.userdata?._id === messageContent.user_id ? (null) : (<h6 className="flex items-center justify-start mb-2">
                                      {messageContent?.author
                                        ? messageContent?.author
                                        : messageContent?.user[0]?.fullname
                                          ? messageContent?.user[0]?.fullname
                                          : `${messageContent?.user[0]?.name}`}
                                    </h6>)} */}
                                    <p className="text-sm">{messageContent.message}</p>
                                  </div>
                                  <div className="text-xs text-gray-500 text-right mt-1">
                                    {messageContent?.createdAt
                                      ? formatDateTime(messageContent?.createdAt)
                                      : formatDateTime(new Date())}
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                      <div className="px-2 relative w-full scrollbar-hide">
                        <textarea
                          className="w-full rounded-full bg-white/80 border-0 outline-none text-gray-700 py-3 text-sm resize-y max-h-[100px] pr-20 pl-4 scrollbar-hide"
                          placeholder="Write a message..."
                          tabIndex="0"
                          rows={1}
                          cols={50}
                          autoComplete="off"
                          value={currMessage}
                          onChange={(event) =>
                            setCurrMessage(event.target.value)
                          }
                          onKeyDown={handleKeyPress}
                        />
                        <button
                          className="absolute right-1 top-1/2 transform -translate-y-1/2 text-black px-4 rounded flex items-center"
                          title="Send message"
                          tabIndex="0"
                          onClick={sendMessage}
                        >
                          <LuSendHorizonal size={25} />
                        </button>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="w-2/5 flex flex-col">
                  <div className="flex justify-center items-center py-2 w-full text-[#000000D4] text-2xl font-bold border-b border-[#D8D8D8]">
                    Resources
                  </div>
                  <div className="py-2 px-4 min-h-[65vh]">
                    <div className="flex flex-col bg-white rounded-3xl min-h-[62vh] border border-[#0000002E]">
                      <ul className="flex justify-between items-center mt-4 px-20 border-b border-[#C7C6C6]">
                        {tabs.map((tab) => (
                          <li key={tab.value} className="mr-4">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setActiveTab(tab.value);
                              }}
                              className={`relative inline-block p-2 rounded-t-lg text-lg outline-none font-bold ${activeTab === tab.value
                                ? 'text-black border-b-2 border-[#F5BD41] '
                                : 'text-gray-600'
                                }`}
                            >
                              {tab.name}
                              <span
                                className={`absolute bottom-0 left-0 w-full h-[2px] outline-none ${activeTab === tab.value
                                  ? 'bg-[#F5BD41]'
                                  : 'bg-transparent hover:bg-[#F5BD41]'
                                  }`}
                              />
                            </button>
                          </li>
                        ))}
                      </ul>
                      {activeTab === 0 && (
                        <div className="flex justify-center items-center">
                        <p className="text-sm  font-semibold text-black">
                          No Documents to display
                        </p>
                        </div>
                      )}
                      {activeTab === 1 && (
                        <div className="flex justify-center items-center">
                        <p className="text-sm  font-semibold text-black">
                          No Images to display
                        </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default MyTherapist;
