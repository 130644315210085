import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { Form } from 'react-bootstrap';
import { Navbar, Nav } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import API from '../../Api/Api';
import AdminRoute from '../../Route/RouteDetails';
import moment from "moment";
// import { LifePreserver } from 'react-bootstrap-icons';
import logo from "../../Assets/Images/Login/ryteLife-full.png";

const JoinGroup = () => {
  const [searchText, setSearchText] = useState('');
  //const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage] = useState(3); // Change the number of items per page here
  const [FocussedData, setFocussedData] = useState([]);
  const [SupportData, setSupportData] = useState([]);

  const settings = {
    infinite: true,
    slidesToShow: 6,
    lazyLoad: true,
    dots: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 5,
          infinite: true,
          arrow: true,
          dots: false
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 5,
          initialSlide: 5
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}`
      );
    }
  };

  // const data = [

  //   {
  //     image: "https://images.pexels.com/photos/1377202/pexels-photo-1377202.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  //     caption: "Second Slide",
  //     title: 'Fight Anxiety  ',
  //     name: 'by Dr. Meenakshi Narayan',
  //     members: 'members : 11/30'
  //   },
  //   {
  //     image: "https://images.pexels.com/photos/3825527/pexels-photo-3825527.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  //     caption: "Third Slide",
  //     title: 'Al Therapist',
  //     name: 'by Dr. Meenakshi Narayan',
  //     members: 'members : 11/30'
  //   },
  //   {
  //     image: "https://images.pexels.com/photos/305564/pexels-photo-305564.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  //     caption: "Fourth Slide",
  //     title: 'Groups',
  //     name: 'by Dr. Meenakshi Narayan',
  //     members: 'members : 11/30'
  //   },
  //   {
  //     image: "https://static.wixstatic.com/media/28139a_614762208bbe43339154022d9f7c20ab~mv2.png/v1/crop/x_2,y_0,w_961,h_958/fill/w_356,h_356,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/2_%20Clinical%20counselling.png",
  //     caption: "Fifth Slide",
  //     title: 'Digital Notes ',
  //     name: 'by Dr. Meenakshi Narayan',
  //     members: 'members : 11/30'
  //   },
  //   {
  //     image: "https://static.wixstatic.com/media/28139a_4092c81e39b249fc97516d0df2aee1fe~mv2.png/v1/crop/x_4,y_0,w_958,h_958/fill/w_356,h_356,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Support%20group.png",
  //     caption: "First Slide",
  //     title: 'Playground',
  //     name: 'by Dr. Meenakshi Narayan',
  //     members: 'members : 11/30'

  //   },
  //   {
  //     image: "https://images.pexels.com/photos/1377202/pexels-photo-1377202.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  //     caption: "Second Slide",
  //     title: 'Therapy Session  ',
  //     name: 'by Dr. Meenakshi Narayan',
  //     members: 'members : 11/30'

  //   }
  // ]

  useEffect(() => {
    hetSupportData()
    getFocussedData()
  }, [])

  const hetSupportData = () => {
    API.Auth.Support_API({
      support: "support"
    }).then((response) => {
      if (response?.data?.status === 1) {
        setSupportData(response?.data?.data_all);
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  const getFocussedData = () => {
    API.Auth.Support_API({
      support: "focused"
    }).then((response) => {
      if (response?.data?.status === 1) {
        setFocussedData(response?.data?.data_all);
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  return (
    <div className="success">
      <div className="dashboard therapist">
        <div className="row d-flex justify-content-center text-center inner">
          <div className="top-bar-nav">
            <Navbar className='top-bar'>
              <NavLink to="/theme"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
              </svg></NavLink>
              <Nav className="mr-auto">
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="Search therapist"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </Form.Group>

                <span className="logo-div">
                  <NavLink to="/"><img src={logo} className="logo" alt="logo" /> </NavLink>
                </span>
              </Nav>
            </Navbar>
          </div>
          <div className="col-md-12">
            <h2 className='heading mb-2 mt-4'>Find strength in our support system</h2>
            <h4 className='create-new_notes secheading mb-2'>Join Our Groups</h4>
          </div>

          <div className="col-md-12">
            <p className="mb-1 focussed-heading mt-4">Focussed Groups</p>
            <Slider {...settings}>
              {FocussedData && FocussedData.map((slide) => {
                return (
                  <div className="card-body" key="">
                    <div className="focussed-div">
                      <span className='focussed-text'>Focussed</span>
                      <img
                        className="d-block w-100"
                        src={slide.image}
                        alt={slide.caption}
                      />
                    </div>
                    <div className="focussed-div-text">
                      <h3 className="card-title">{slide?.title}</h3>
                      <p className="card-text mb-0">{slide?.name}</p>
                      <p className="card-text mb-0">{slide?.members}</p>
                      <p className="startDate mt-1 mb-1">Start Date: 10/09/2023</p>
                      <p className="endDate mb-1">End Date : 21/12/2024</p>
                      <Button className="transparent-btn mt-2 mb-2">
                        Registration
                      </Button>
                    </div>

                  </div>
                )
              })}
            </Slider>
          </div>

          <div className="col-md-12">
            <p className="mt-4 mb-1 focussed-heading">Support Groups</p>
            <Slider {...settings}>
              {SupportData && SupportData.map((slide) => {
                return (
                  <div className="card-body" key="">
                    <div className="support-div">
                      <span className='support-text'>Support</span>
                      <img
                        className="d-block w-100"
                        src={slide?.image}
                        alt={slide?.caption}
                      />
                    </div>

                    <div className="support-div-text">
                      <h3 className="card-title">{slide?.title}</h3>
                      <p className="card-text mb-0">{slide?.name}</p>
                      <p className="card-text mb-0">{slide?.members}</p>
                      <p className="startDate mt-1 mb-1">Start Date: {moment(slide?.startdate).format("DD/MMM/YYYY")}</p>
                      <p className="endDate mb-1">End Date : {moment(slide?.enddate).format("DD/MMM/YYYY")}</p>
                      <Button className="transparent-btn mt-2 mb-2" >
                        Registration
                      </Button>
                    </div>
                  </div>
                )
              })}
            </Slider>

            <div className='col-md-12 d-flex skip'>
              {/* <div className='mt-4 mb-4 skip-text'> <NavLink to={`../${AdminRoute?.Common?.Dashboard}`}>Skip </NavLink></div> */}
              <div>
                <Button type="submit" className="button_common mt-4 ml-4 next-btn">
                  <NavLink to={`../${AdminRoute?.Common?.Dashboard}`}>Next </NavLink>
                </Button>
              </div>
            </div>
          </div>
        </div>

      </div >
    </div>
  )
}
export default JoinGroup;