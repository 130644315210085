import React, { useState, useEffect } from 'react';
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import AdminRoute from "../Route/RouteDetails";
import API from "../Api/Api";
import moment from "moment";
import { isBeforeOrAfterCurrent, combineDateTime } from "./Common/Functions";
import { toast } from 'react-toastify';
import preloader from '../Assets/Images/Flip-Flop.gif';
import { useNavigate } from 'react-router-dom';


const AppointmentModal = ({ isOpen, onClose, therapistId }) => {
    const navigate = useNavigate();
    const currentDate = new Date();
    const minDate = new Date();
    const [value, onChange] = useState(currentDate.toString());
    const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
    const [therapistData, setTherapishData] = useState({});
    const [timeSlots, setTimeSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState({});
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [submit, setSubmit] = useState(false);
    // const ResheduleAppointmentId = JSON.parse(localStorage.getItem("ResheduleAppointmentId"));

    const correctedId = therapistId.trim();

    useEffect(() => {
        if(isOpen){
            getTherapistDetails();
        }
    }, [value, isOpen]);

    const getTherapistDetails = () => {
        setLoading(true);
        setError("");
        setSelectedSlot({});
        API.Therapist.getTherpistDetails({
            date: moment(value).format("YYYY-MM-DD"),
            id: correctedId,
        })
            .then((response) => {
                console.log('TherapistResponse', response);
                if (response?.data?.status === 1) {
                    console.log(response?.data?.data_all);
                    setTimeSlots(response?.data?.data_all[0]?.theropist_calender?.time);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const handleSlotClick = (slot) => {
        if (Object.values(slot)[0]) {
            setSelectedSlot(slot);
        }
    };

    // const handleNext = () => {
    //     console.log('1');
    //     setSubmit(true);
    //     if (ResheduleAppointmentId) {
    //         API.Session.DeleteSession({
    //             id: ResheduleAppointmentId,
    //         })
    //             .then((response) => {
    //                 console.log('2');
    //                 if (response?.data?.status === 1) {
    //                     handelNextApi();
    //                     setSubmit(false);
    //                 }
    //             })
    //             .catch((error) => {
    //                 setSubmit(false);
    //                 console.log(error);
    //             });
    //     } else {
    //         setSubmit(false);
    //         console.log('3');
    //         handelNextApi();
    //     }
    // };
    const handleNext = () => {
        setSubmit(true);
        const ResheduleAppointmentId = sessionStorage.getItem("ResheduleAppointmentId");    
        if (ResheduleAppointmentId) {
            API.Session.DeleteSession({
                id: ResheduleAppointmentId,
            })
                .then((response) => {
                    if (response?.data?.status === 1) {
                        sessionStorage.setItem("ResheduleAppointmentId", '');
                        handelNextApi();
                    }else if(response?.data?.status === 0){
                        sessionStorage.setItem("ResheduleAppointmentId", '');
                        toast.error(response?.data?.message);
                        navigate(`../${AdminRoute?.Common?.MySession}`);
                    }
                    setSubmit(false);
                })
                .catch((error) => {
                    console.log("API Error:", error);
                    setSubmit(false);
                });
        } else {
            setSubmit(false);
            handelNextApi();
        }
    };
    
    const handelNextApi = () => {
        if (Object.keys(selectedSlot)[0]) {
            const inputDateTime = combineDateTime(
                moment(value).format("YYYY-MM-DD"),
                Object.keys(selectedSlot)[0]
            );
            let ISTtime = moment(inputDateTime).format("YYYY-MM-DDTHH:mm:ss");
            ISTtime = ISTtime.toLocaleString();
            setError("");
            debugger;
            API.Session.CreateSession({
                data: {
                    clientId: UserData?.userdata?._id,
                    theropistId: therapistId,
                    // date: moment(value).format("YYYY-MM-DD"),
                    // timestart: Object.keys(selectedSlot)[0],
                    date: ISTtime + "+00:00",
                    timestart: Object.keys(selectedSlot)[0],
                    timeend: "11PM",
                },
            })
                .then((response) => {
                    if (response?.data?.status === 1) {
                        onClose();
                        toast.success('Appointment Booked Successfully');
                        setSubmit(false);
                        navigate(`../${AdminRoute?.Common?.MySession}`);
                        // if (AppointmentId || check) {
                        //   navigate(`../${AdminRoute?.Common?.Dashboard}`);
                        // } else {
                        //   navigate(`../${AdminRoute?.Common?.Join_Group}`);
                        // }
                    } else {
                        toast.error(response?.data?.message);
                        setError(response?.data?.message);
                        navigate(`../${AdminRoute?.Common?.MySession}`);
                    }
                })
                .catch((error) => {
                    // toast.error(response?.data?.message);
                    console.log(error);
                });
        } else {
            setSubmit(false);
            toast.error("Select time slot.");
        }
    };



    if (!isOpen) return null;


    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="rounded-lg max-w-[90%] relative overflow-hidden flex flex-col py-12 px-4 bg-white">
                <button
                    className="absolute top-2 right-2 text-2xl font-bold text-gray-700 hover:text-gray-900"
                    onClick={onClose}
                >
                    &times;
                </button>
                <div className="flex-1 flex w-full h-full">
                    <div className="p-6">
                        <h3 className="text-lg font-semibold text-gray-800 mb-4">Choose a Date</h3>
                        <Calendar onChange={onChange} value={value} minDate={minDate} />
                    </div>
                    <div className="p-6 h-[400px] w-[800px]">
                        <h3 className="text-lg font-semibold text-gray-800 mb-4">Available Slots</h3>
                        {!loading ? (
                            <>
                                <p className="text-gray-700 mb-2">Tap on the time slot that suits you:</p>
                                <div className="grid grid-cols-5 gap-2">
                                    {timeSlots
                                        ?.filter(slot => Object.keys(slot)[0] !== "Select Time") // Exclude the "Select Time" slot
                                        .map((slot, index) => {
                                            const isDisabled = Object.values(slot)[0] === false; // Check if the slot is false
                                            return (
                                                <button
                                                    key={index}
                                                    className={`px-4 py-2 rounded-xl ${Object.keys(selectedSlot)[0] === Object.keys(slot)[0]
                                                        ? "bg-[#F5BD41] text-white"
                                                        : "bg-white text-gray-900"
                                                        }`}
                                                    onClick={() => !isDisabled && handleSlotClick(slot)} // Prevent click if disabled
                                                    style={{
                                                        border: '1px solid #F5BD41',
                                                        cursor: isDisabled ? 'not-allowed' : 'pointer', // Change cursor style
                                                        opacity: isDisabled ? 0.5 : 1, // Optional: Reduce opacity for disabled slots
                                                    }}
                                                    disabled={isDisabled} // Disable the button
                                                >
                                                    {Object.keys(slot)}
                                                </button>
                                            );
                                        })}

                                </div>
                            </>
                        ) : (
                            <div className='w-full flex justify-center items-center'>
                                <div className='h-10 w-10 rounded-full'>
                                    <img src={preloader} className='h-full w-full object-cover' />
                                </div>
                            </div>
                        )}
                        {/* <p className="text-red-500 mt-2">{error}</p> */}
                        {/* {Object.keys(therapistData).length && !timeSlots?.length && !loading && (
                            <p className="text-red-500">Slots are not available</p>
                        )} */}
                    </div>
                </div>
                <div className='flex w-full justify-end'>
                    <button className='bg-[#FFB81D] px-8 py-2 text-black text-sm rounded-lg shadow-lg' onClick={handleNext}>Book Session </button>
                </div>
            </div>
        </div>
    );
};

export default AppointmentModal;
