/* eslint-disable */
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AdminRoute from './FinalRoute';
import PageNotFound from './../Components/Common/PageNotFound'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const LOADING = (
    <div className="home-page">
        <div className="container-fluid main-container">
            <div className="row library-row center-me padding-top-40-percent">
                <div className="col-12 center-me">
                    <div className="spinner-grow text-light" role="status">
                    <div className="container_loader"></div> <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
const Lazy = () => {
    return (
        <BrowserRouter>
             <ToastContainer/>
            <Suspense fallback={LOADING}>
                <Routes >
                    {AdminRoute?.map((page) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <Route key={page.path} {...page} />
                    ))}
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
};

export default Lazy;
