import React from 'react';
import baseApi from '../../Api/config';

const PlaygroundCard = ({ data, cardBg, onClick  }) => {
    return (
        <div className="rounded-2xl overflow-hidden w-64 p-2 border border-[#C7D5E0] hover:shadow-lg m-2" style={{ background: cardBg }} onClick={() => onClick(data)}>
            <img
                src={data?.backgroundimagename}
                alt={data.header}
                className="w-full h-40 object-cover rounded-2xl shadow-md"
            />
            <div className="p-2 text-center mb-1">
                <h3 className="font-bold text-lg">{data.header}</h3>
            </div>
        </div>
    );
};

export default PlaygroundCard;
