import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import { toast } from "react-toastify";
import SuccessPop from "../Common/modal/SuccessPop";
import API from "../../Api/Api";
import LoginPageBg from "../../Assets/Images/Login/loginBg.png";
import logo from "../../Assets/Images/Login/ryteLife-full.png";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const path = useParams();
  // const mobile = JSON.parse(localStorage.getItem("Mobileno"));
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const mobile_no = JSON.parse(queryParams.get("mobile_no"));
  const [counter, setCounter] = useState(30);
  const [showPopup, setShowPopUp] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState("");
  const numberOfDigits = 4;
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const otpBoxReference = useRef([]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // Code to execute after 30 seconds
    }, 30000);

    const interval = setInterval(() => {
      if (counter) {
        setCounter((prevCounter) => prevCounter - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [counter]);

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  const handleVerifyOtp = (e) => {
    setIsSubmit(true);
    e.preventDefault();
    if (path.path == 2) {
      try {
        API.Auth.VerifyViaMobile({
          data: {
            phone: mobile_no,
            otp: otp.join(""),
            role: "Client",
          },
        }).then((response) => {
          if (response?.data?.status === 1) {
            localStorage.setItem("AuthTokenClient", response?.data?.token);
            localStorage.setItem(
              "ClientUserData",
              JSON.stringify(response?.data)
            );
            // toast.success(response?.data?.message);
            setError("");
            navigate(`../${AdminRoute?.Auth?.verifyOtpMail}/2?email=${email}`);
          } else {
            setError(response?.data?.message);
            // toast.error(response?.data?.message);
          }
          setIsSubmit(false);
        });
      } catch (error) {
        console.log(error);
        setIsSubmit(false);
      }
    }
    if (path.path == 1) {
      try {
        API.Auth.VerifyViaMobile({
          data: {
            phone: mobile_no,
            otp: otp.join(""),
            role: "Client",
          },
        }).then((response) => {
          if (response?.data?.status === 1) {
            localStorage.setItem("AuthTokenClient", response?.data?.token);
            localStorage.setItem(
              "ClientUserData",
              JSON.stringify(response?.data)
            );
            // setShowPopUp(true);
            // setTimeout(() => {
            //   setShowPopUp(false)
            //   navigate(`../${AdminRoute?.Common?.Dashboard}`);
            // }, 2000)

            if (
              !response?.data.userdata?.is_verified ||
              !response?.data.userdata?.is_verifiedp
            ) {
              if (!response?.data.userdata?.is_verifiedp) {
                navigate(
                  `../${AdminRoute?.Auth?.VerifyOtp
                  }/1?mobile_no=${JSON.stringify(
                    response?.data.userdata?.email
                  )}`
                );
              }
              if (!response?.data.userdata?.is_verified) {
                navigate(
                  `../${AdminRoute?.Auth?.VerifyOtp
                  }/1?mobile_no=${JSON.stringify(
                    response?.data.userdata?.phone
                  )}`
                );
              }
            } else if (
              response?.data.userdata?.is_verifiedp &&
              response?.data.userdata?.is_verified
            ) {
              // if (response?.data.userdata.step == 3) {
              //   navigate(`../${AdminRoute?.Common?.Group}`);
              // } else if (response?.data.userdata.step == 4) {
              //   navigate(`../${AdminRoute?.Common?.Plan}`);
              // } else if (response?.data.userdata.step == 5) {
              //   navigate(`../${AdminRoute?.Common?.Therapist}`);
              // } else if (response?.data?.userdata?.step == 6) {
              //   navigate(`../${AdminRoute?.Common?.Dashboard}`);
              //   setTimeout(() => {
              //     // setShowPopUp(false);
              //     navigate(`../${AdminRoute?.Common?.Dashboard}`);
              //   }, 2000);
              
              // }
              navigate(`../${AdminRoute?.Common?.Dashboard}`);
            }
          } else {
            //  toast.error(response?.data?.message);
            setError(response?.data?.message);
          }
          setIsSubmit(false);
        });
      } catch (error) {
        console.log(error);
        setIsSubmit(false);
      }
    }
  };

  const handleResendOtp = (e) => {
    e.preventDefault();
    try {
      API.Auth.LoginAPIMobile({
        data: {
          phone: mobile_no,
          role: "Client",
          resend: true,
        },
      }).then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.message);
          setError("");
        } else {
          setError(response?.data?.message);
          // toast.error(response?.data?.message);
        }
        setOtp(["", "", "", ""]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendOtpEmail = (email) => {
    try {
      API.Auth.LoginAPIEmail({
        data: {
          email: email,
          role: "Client",
        },
      }).then((response) => {
        if (response?.data?.status === 1) {
          // toast.success(response?.data?.message);
        } else {
          // toast.error(response?.data?.message);
        }
        setOtp(["", "", "", ""]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {showPopup ? (
        <SuccessPop text="Login Successful" />
      ) : (
        // <div className="Otp">
        //   <div className="container login-page">
        //     <div className="row main-container">
        //       <div className="col-md-6 col-sm-12 left-text">
        //         <div className="logo-div">
        //           <NavLink to="/">
        //             <img src="/images/logo.png" className="logo" alt="logo" />{" "}
        //           </NavLink>
        //         </div>
        //         <h1 className="heading">Reclaim control over your</h1>
        //         <p className="subheading">Mental Health journey</p>
        //         <p>
        //           through our online therapy programs, guided by our
        //           compassionate team of psychologists.
        //         </p>
        //       </div>
        //       <div className="col-md-6 col-sm-12">
        //         <div className="otp-div">
        //           <h2 className="heading">Verify via Mobile OTP</h2>

        //           <p>
        //             <span className="text-danger fw-bold">*</span> Enter 4 digit
        //             OTP sent on the registered{" "}
        //             <b>
        //               &quot;XXXXXX{mobile_no?.substr(mobile_no?.length - 4)}
        //               &quot;
        //             </b>
        //             mobile no.
        //           </p>
        //           <div
        //             style={{ justifyContent: "space-between", display: "flex" }}
        //           >
        //             {otp.map((digit, index) => (
        //               <input
        //                 key={index}
        //                 value={digit}
        //                 maxLength={1}
        //                 onChange={(e) => handleChange(e.target.value, index)}
        //                 onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
        //                 ref={(reference) =>
        //                   (otpBoxReference.current[index] = reference)
        //                 }
        //                 className="form-control custom-input-text-primary otp-input mb-0"
        //                 autoComplete="off"
        //               />
        //             ))}
        //           </div>
        //           <button
        //             className="button_common"
        //             disabled={isSubmit}
        //             onClick={(e) => handleVerifyOtp(e)}
        //           >
        //             Verify and Proceed
        //           </button>
        //           {error && <p className="text-danger">{error}</p>}
        //           <p
        //             className="orange-small-link text-center text-decoration-underline cursor_pointer resend-otp"
        //             onClick={(e) => handleResendOtp(e)}
        //           >
        //             Resend OTP
        //           </p>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </div>
        <div className="w-full bg-gradient-to-b from-[#DDE4ED] to-[#FAE2DF] min-h-screen flex items-center justify-center py-[1%] px-[2%] scrollbar-hide">
          <div className="bg-white bg-opacity-75 rounded-[50px] shadow-lg w-full flex py-[3%]">
            <div className="grid grid-cols-2 md:grid-cols-5 gap-4 pl-0 md:pl-[6%]">
              <div className="bg-white rounded-[50px] shadow-lg px-12 col-span-2 py-6">
                <div className="flex justify-center py-4">
                  <img src={logo} className="h-auto w-full max-w-[200px]" />
                </div>
                <div className="flex justify-center pb-4">
                  <span className="font-extrabold font-poppins-bold text-2xl">BEGIN YOUR WELLNESS JOURNEY</span>
                </div>
                <div className="flex justify-center pb-4">
                  <span className="font-bold font-poppins-regular text-xl">Verify via Mobile OTP</span>
                </div>
                <div className="w-full">                 
                    <span className="text-danger fw-bold">*</span> Enter 4 digit
                    OTP sent on the registered{" "}
                    <b>
                      &quot;XXXXXX{mobile_no?.substr(mobile_no?.length - 4)}
                      &quot;
                    </b>
                    mobile no.
                  
                  <div className="mt-2"
                    style={{ justifyContent: "space-between", display: "flex" }}
                  >
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        value={digit}
                        maxLength={1}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                        ref={(reference) =>
                          (otpBoxReference.current[index] = reference)
                        }
                        className="form-control custom-input-text-primary otp-input mb-0 mr-1 outline-none"
                        autoComplete="off"
                      />
                    ))}
                  </div>
                  <div className="flex justify-center item-center">
                  <button
                   className="w-fit mt-3 mb-3 font-bold py-2 px-4 rounded-[30px] border-0 bg-[#F5BD41] text-black"
                    disabled={isSubmit}
                    onClick={(e) => handleVerifyOtp(e)}
                  >
                    Verify and Proceed
                  </button>
                  </div>
                  
                  {error && <p className="text-danger">{error}</p>}
                  <p
                    className="orange-small-link text-center text-decoration-underline cursor_pointer resend-otp"
                    onClick={(e) => handleResendOtp(e)}
                  >
                    Resend OTP
                  </p>

                </div>
              </div>
              <div className="hidden md:flex flex-col justify-center col-span-3 ml-[12%]">
                <h2 className="text-4xl text-gray-900 font-lusitana font-normal leading-snug mr-[16%] py-[4%]">Experience 24/7 companionship for your mental well-being grow with personalized support.</h2>
                {/* <h3 className="text-md text-gray-700 py-[3%] font-medium ">Sign in or create your account now to start your journey to better mental health</h3> */}
                <img src={LoginPageBg} alt="LoginPageBanner" className="h-auto w-full max-w-[400px]" />
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default VerifyOtp;
