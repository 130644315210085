import React, { useState, useEffect } from "react";
import { Pagination, Form } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import Header from '../Common/Header';
import Sidebar from '../Common/Sidebar';
import TherapistCard from "./TherapistCard";
import { CiSearch } from "react-icons/ci";
import AppointmentModal from "../AppointmentModal";

const Therapist = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(3);
  const [searchText, setSearchText] = useState("");
  const [Therpist_List, setTherpist_List] = useState([]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [selectedTherpist, setSelectedTherpist] = useState(null);
  const [isSubmit, setIssubmit] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const check = queryParams.get("key");
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false);
  const [therapistId, setTherapistId] = useState('');
  

  // const [Error, setError] = useState("");

  // api call for therpist
  const getTherpistList = () => {
    setLoading(true);
    try {
      API.Auth.TherpistList_API({
        Therapist: "Therapist",
        page: 0,
        name: "",
      }).then((response) => {
        setTherpist_List(response?.data?.data_all);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Filter data based on search text
  const filteredData = Therpist_List.filter(
    (val) =>
      val?.fullname?.toLowerCase().includes(searchText.toLowerCase()) ||
      val?.email?.toLowerCase().includes(searchText.toLowerCase())
  );
  // const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  // const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };

  useEffect(() => {
    getTherpistList();
  }, []);

  // submit selected therapist list
  // const handleTherapist = () => {
  //   if (!isSubmit) {
  //     setIssubmit(true);
  //     API.Therapist.TherpistAdd({
  //       data: {
  //         clientId: UserData?.userdata?._id,
  //         theropistId: selectedTherpist?._id,
  //       },
  //     })
  //       .then((response) => {
  //         if (response.data.status === 1) {
  //           setIssubmit(false);
  //           localStorage.setItem("TherapistID", selectedTherpist?._id);
  //           handleStep();
  //           if (check) {
  //             navigate(
  //               `../${AdminRoute?.Common?.Appointment_scheduler}/?key=true`
  //             );
  //           } else {
  //             navigate(`../${AdminRoute?.Common?.Appointment_scheduler}`);
  //           }
  //         }
  //         // if(res)
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // };

  // const handleStep = () => {
  //   try {
  //     API.Auth.UpdateProfileAPI({
  //       data: {
  //         step: 6,
  //       },
  //       UserId: UserData?.userdata?._id,
  //     }).then((response) => {
  //       console.log(response);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleAppointment = (id) => {
    console.log('Therposit', id);
    setAppointmentModalOpen(true);
    setTherapistId(String(id));
  }
  return (
    <>
      {/* <div className="select-therapist-wrap main-container dashboard therapist pb-0">
      <div className="row inner my-4 height100vh overflow-auto">
        <div className="col-md-12">
          <Navbar className="top-bar mb-5 active justify-content-end">
            <Nav className="mr-auto d-flex">
              <Form.Group className="position-relative select-therapist-search me-2">
                <i className="fa fa-search position-absolute"></i>
                <Form.Control
                  type="text"
                  placeholder="Search therapist"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </Form.Group>

              <span className="logo-div">
                <NavLink to="/">
                  <img src="/images/logo.png" className="logo" alt="logo" />{" "}
                </NavLink>
              </span>
            </Nav>
          </Navbar>
        </div>
        <div className="col-md-12 mb-4">
          <h2 className="heading mb-2 mt-4 mx-2">
            Begin Your Mental Health Journey
          </h2>
          <h4 className="secheading mb-2 mx-2">With Our Friendly Experts</h4>
          <p className=" text-muted mx-2">please select your therapist</p>
        </div>
        <div className="col-12">
          <div className="row">
            {loading ? (
              <figure>
                <div className="dot white"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </figure>
            ) : currentItems.length === 0 ? (
              <div
                className="alert alert-warning digital-notes-alert"
                role="alert"
              >
                Data Not Found
              </div>
            ) : (
              currentItems &&
              currentItems.map((val) => (
                <div
                  className="col-md-4 mb-4 therapist-doctor"
                  key={val._id}
                  onClick={() => setSelectedTherpist(val)}
                >
                  <div
                    className={
                      selectedTherpist?._id === val?._id
                        ? "inner-selected inner-div d-flex h-100"
                        : "inner-div d-flex h-100 cursor-pointer"
                    }
                  >
                    <div className="img d-flex justify-content-center align-items-center">
                      <img
                        src={
                          val?.image
                            ? val.image
                            : `${process.env.REACT_APP_BASE_URL}images/profile.png`
                        }
                        alt="theripist img"
                      />
                    </div>
                    <div className="therapist-doctor-text-div">
                      <h3 className="fw-bold">
                        {val?.fullname}({val?.experties})
                      </h3>
                      <p>{val?.qote}</p>
                      <p>{val?.experties}</p>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="d-flex flex-column-reverse mt-4">
          <p className="">Showing 1 of 3</p>
          <Pagination>
            <Pagination.First
              onClick={() => {
                setCurrentPage(1);
              }}
            />
            <Pagination.Prev
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
            />
            {Array.from({ length: totalPages }).map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
            />
            <Pagination.Last
              onClick={() => {
                setCurrentPage(totalPages);
              }}
            />
          </Pagination>
        </div>

        <div className="col-md-12 d-flex justify-content-end">
          <span className="my-auto">
            <NavLink to={`../${AdminRoute?.Common?.Dashboard}`}>Skip </NavLink>
          </span>
          <Button
            type="submit"
            className="button_common mt-4 mx-2"
            onClick={() => handleTherapist()}
            disabled={!selectedTherpist}
          >
            Next
          </Button>
        </div>
      </div>
    </div> */}
      <div className="flex flex-col bg-white mx-0 w-full h-full min-h-screen" >
        <div className="flex-grow bg-white m-0 w-full p-0" >
          <Header />
          <Sidebar />
          <div className="flex flex-grow md:ml-[8%] h-full md:mr-[3%] md:mt-8 relative z-1 p-4 rounded-3xl shadow-md min-h-[80vh] mt-4 bg-[#F5F6FB80] border border-[#E7F0FC]" >
            <div className="flex-grow h-full bg-transparent rounded-3xl">
              <div className="flex justify-between items-center">
                <div className="flex flex-col">
                  <span className='text-black font-bold text-3xl mb-2'>Our Therapists</span>
                  <span className='text-gray-700 text-lg'>Begin your Mental Health Journey with our Friendly Experts</span>
                </div>
                <div className='flex justify-center items-center mt-2 pb-2 w-72'>
                  <div class="relative w-full">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                      <CiSearch size={18} />
                    </div>
                    <input type="text" className="bg-white border border-gray-300 text-gray-900 text-sm rounded-full  block w-full ps-10 p-2.5  outline-none" placeholder="Search Therapist" onChange={(e) => setSearchText(e.target.value)} />
                  </div>
                </div>
              </div>

              <div className='grid grid-cols-3 gap-10 mt-4 max-h-[62vh] overflow-y-auto overflow-x-hidden scrollbar-hide'>
                {filteredData.length > 0 && filteredData.map((therapist, index) => (
                  <TherapistCard key={index} data={therapist} onClick={() => handleAppointment(therapist._id)} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="hero_area relative z-0">
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 192, 203, 0.7)" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(169, 169, 169, 0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(169, 169, 169, 0.7)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255, 192, 203, 1)" />
            </g>
          </svg>
        </div>
        <AppointmentModal isOpen={appointmentModalOpen} onClose={() => setAppointmentModalOpen(false)} therapistId={therapistId} />
      </div>
    </>
  );
};

export default Therapist;
