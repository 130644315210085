
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { ArrowLeft } from "react-bootstrap-icons";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
// import { toast } from "react-toastify";
import LoginPageBg from "../../Assets/Images/Login/loginBg.png";
import logo from "../../Assets/Images/Login/ryteLife-full.png";


const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = JSON.parse(queryParams.get("email"));
  const mobile_no = JSON.parse(queryParams.get("mobile_no"));

  const initialValues = {
    firstName: "",
    lastName: "",
    birthDate: "",
    email: "",
    PhoneNumber: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (email) {
      setFormData({
        ...formData,
        email: email,
      });
    }
    if (mobile_no) {
      setFormData({
        ...formData,
        PhoneNumber: mobile_no,
      });
    }
  }, [])

  const RegisterValidate = (values) => {
    const errors = {};
    const checkMobile = /^[789]\d{9}$/
    const emailRegrex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;

    if (!values?.firstName) {
      errors.firstName = "First name is required";
    }
    if (!values?.lastName) {
      errors.lastName = "Last name is required";
    }
    if (!values?.email) {
      errors.email = "Email is required";
    } else if (!emailRegrex.test(values?.email)) {
      errors.email = "Enter valid Email";
    }
    if (!values?.birthDate) {
      errors.birthDate = "Birth date is required";
    }
    if (!values.PhoneNumber) {
      errors.PhoneNumber = "Phone number is required";
    } else if (!checkMobile.test(values?.PhoneNumber)) {
      errors.PhoneNumber = "Enter valid Phone number";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      try {
        API.Auth.Register({
          data: {
            name: formData?.firstName,
            last_name: formData?.lastName,
            dob: formData?.birthDate,
            email: formData?.email,
            phone: formData?.PhoneNumber,
            role: "Client",
          },
        }).then((response) => {
          if (response?.data?.status === 1) {
            // localStorage.setItem("EmailId", response?.data?.data?.email);
            // localStorage.setItem("Mobileno", response?.data?.data?.phone);
            navigate(`../${AdminRoute?.Auth?.VerifyOtp}/2?email=${JSON.stringify(formData?.email)}&mobile_no=${JSON.stringify(formData?.PhoneNumber)}`);
            // toast.success(response?.data?.message);
            setError("");
          }
          else {
            // toast.error(response?.data?.message);
            setError(response?.data?.message);
          }
        })
          .catch((error) => {
            console.log('Error', error.message);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, [formErrors]);


  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(RegisterValidate(formData));
    setIsSubmit(true);
  };

  const disableFutureDate = () => {
    // const today = new Date().toISOString().split('T')[0];
    // return today;
    const currentDate = new Date();
    const maxDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
    return maxDate.toISOString().split('T')[0];
  }

  return (
    <>
      {/* <div className="container">
        <div className="row main-container">
          <div className="col-md-6 col-sm-12 left-text">
            <h1 className="heading">Begin your transformative journey with our online therapy. </h1>
            <p>
              Navigate your path to well-being with our dedicated psychologists by your side, every step of the way.
            </p>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="register">
              <h2 className="heading">
                {" "}
                <NavLink to="/">
                  <i className="fa fa-chevron-left left-arrow"></i>
                </NavLink>
                Register
              </h2>
              <form>
                <div>
                  <label><span className="text-danger fw-bold">*</span> First Name:</label>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="Enter First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                    autoComplete="off" g
                    required
                  />
                  <p className="text-danger">{formErrors?.firstName}</p>
                </div>
                <div>
                  <label><span className="text-danger fw-bold">*</span> Last Name:</label>
                  <input
                    type="text"
                    placeholder="Enter Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                  <p className="text-danger">{formErrors?.lastName}</p>
                </div>
                <div>
                  <label><span className="text-danger fw-bold">*</span> Birth Date:</label>
                  <input
                    type="date"
                    placeholder="Enter BirthDate"
                    name="birthDate"
                    value={formData.birthDate}
                    onChange={handleChange}
                    autoComplete="off"
                    max={disableFutureDate()}

                    required
                  />
                  <p className="text-danger">{formErrors?.birthDate}</p>
                </div>
                <div>
                  <label><span className="text-danger fw-bold">*</span> E-Mail ID:</label>
                  <input
                    type="email"
                    placeholder="Enter E-Mail ID"
                    name="email"
                    value={formData.email}
                    autoComplete="off"
                    onChange={handleChange}
                    required
                  />
                  <p className="text-danger">{formErrors?.email}</p>
                </div>
                <label><span className="text-danger fw-bold">*</span> Phone Number:</label>
               
                <input
                  type="text"
                  name="PhoneNumber"
                  value={formData.PhoneNumber}
                  autoComplete="off"
                  placeholder="Enter Mobile Number"
                  onChange={handleChange}
                  required
                />
                <p className="text-danger">{formErrors?.PhoneNumber}</p>

                <Button
                  className="button_common"
                  onClick={(e) => handleSubmit(e)}
                >
                  Verify via OTP
                </Button>
                {error && <p className="text-danger">{error}</p>}
              </form>
            </div>
          </div>

        </div>
      </div> */}
      <div className="w-full bg-gradient-to-b from-[#DDE4ED] to-[#FAE2DF] min-h-screen flex items-center justify-center py-[1%] px-[2%] scrollbar-hide">
        <div className="bg-white bg-opacity-75 rounded-[50px] shadow-lg w-full flex py-[3%]">
          <div className="grid grid-cols-2 md:grid-cols-5 gap-4 pl-0 md:pl-[6%]">
            <div className="bg-white rounded-[50px] shadow-lg px-12 col-span-2 py-6">
              <div className="flex justify-center py-4">
                <img src={logo} className="h-auto w-full max-w-[200px]" />
              </div>
              <div className="flex justify-center pb-4">
                <span className="font-extrabold font-poppins-bold text-2xl">BEGIN YOUR WELLNESS JOURNEY</span>
              </div>
              <div className="flex justify-center pb-4">
                <span className="font-bold font-poppins-regular text-xl">Register</span>
              </div>
              <form>
                <div>
                  <label className="block text-gray-700 text-xs font-bold mb-2 ml-[2%]"><span className="text-danger fw-bold">*</span> First Name:</label>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="Enter First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                    className="bg-[#d9d9d94d] mb-2 appearance-none border-2 border-[#d9d9d94d] rounded-[30px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none "
                  />
                  <p className="text-danger">{formErrors?.firstName}</p>
                </div>
                <div>
                  <label className="block text-gray-700 text-xs font-bold mb-2 ml-[2%]"><span className="text-danger fw-bold">*</span> Last Name:</label>
                  <input
                    type="text"
                    placeholder="Enter Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                    className="bg-[#d9d9d94d] mb-2 appearance-none border-2 border-[#d9d9d94d] rounded-[30px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none "
                  />
                  <p className="text-danger">{formErrors?.lastName}</p>
                </div>
                <div>
                  <label className="block text-gray-700 text-xs font-bold mb-2 ml-[2%]"><span className="text-danger fw-bold">*</span> Birth Date:</label>
                  <input
                    type="date"
                    placeholder="Enter BirthDate"
                    name="birthDate"
                    value={formData.birthDate}
                    onChange={handleChange}
                    autoComplete="off"
                    max={disableFutureDate()}
                    className="bg-[#d9d9d94d] mb-2 appearance-none border-2 border-[#d9d9d94d] rounded-[30px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none "
                    required
                  />
                  <p className="text-danger">{formErrors?.birthDate}</p>
                </div>
                <div>
                  <label className="block text-gray-700 text-xs font-bold mb-2 ml-[2%]"><span className="text-danger fw-bold">*</span> E-Mail ID:</label>
                  <input
                    type="email"
                    placeholder="Enter E-Mail ID"
                    name="email"
                    value={formData.email}
                    autoComplete="off"
                    onChange={handleChange}
                    required
                    className="bg-[#d9d9d94d] mb-2 appearance-none border-2 border-[#d9d9d94d] rounded-[30px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none "
                  />
                  <p className="text-danger">{formErrors?.email}</p>
                </div>
                <label className="block text-gray-700 text-xs font-bold mb-2 ml-[2%]"><span className="text-danger fw-bold">*</span> Phone Number:</label>
                <input
                  type="text"
                  name="PhoneNumber"
                  value={formData.PhoneNumber}
                  autoComplete="off"
                  placeholder="Enter Mobile Number"
                  onChange={handleChange}
                  required
                  className="bg-[#d9d9d94d] mb-2 appearance-none border-2 border-[#d9d9d94d] rounded-[30px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none "
                />
                <p className="text-danger">{formErrors?.PhoneNumber}</p>
                <div className="w-full flex justify-center items-center">
                  <button
                    type="submit"
                    className="w-40 mt-3 mb-3 font-bold py-2 px-4 rounded-[30px] border-0 bg-[#F5BD41] text-black"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Verify via OTP
                  </button>
                </div>
                {error && <p className="text-danger">{error}</p>}
              </form>

            </div>
            <div className="hidden md:flex flex-col justify-center col-span-3 ml-[12%]">
              <h2 className="text-4xl text-gray-900 font-lusitana font-normal leading-snug mr-[16%] py-[4%]">Experience 24/7 companionship for your mental well-being grow with personalized support.</h2>
              {/* <h3 className="text-md text-gray-700 py-[3%] font-medium ">Sign in or create your account now to start your journey to better mental health</h3> */}
              <img src={LoginPageBg} alt="LoginPageBanner" className="h-auto w-full max-w-[400px]" />
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default Register;
