// App.js
import React, { useEffect, useState } from 'react';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import Button from 'react-bootstrap/Button';
import { NavLink, useNavigate } from "react-router-dom";
import API from '../../Api/Api';
import AdminRoute from "../../Route/RouteDetails"
import { checkDate } from '../Common/Functions';
import moment from 'moment';

import AiTherapist from "../../Assets/Images/ai-therapist.jpg";
import PlayGround from "../../Assets/Images/playground.jpg";
import Groups from "../../Assets/Images/groups.jpg";
import DigitalNotes from "../../Assets/Images/digital-notes-payment.jpg";


function Payment() {
  const navigate = useNavigate()
  const [CurrentPlan, setCurrentPlan] = useState([]);
  const [PlanDeatils, setPlanDeatila] = useState({});
  const [paymenList, setPaymentLsit] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [featurePrice, setFeaturePrice] = useState([]);

  useEffect(() => {
    MyPlanlfeatures();
    getCurrentSession();
    FeaturePriceList();
  }, []);


  const MyPlanlfeatures = () => {
    try {
      API.Payment.MyPlanlfeatures()
        .then((response) => {
          if (response?.status === 200) {
            setCurrentPlan(response?.data?.data)
            setPlanDeatila(response?.data?.plandetails)
          }
        });
    } catch (error) {
      console.log(error);
    }
  }


  const FeaturePriceList = () => {
    try {
      API.Payment.FeaturePriceList().then((response) => {
        if (response?.status === 200) {
          setFeaturePrice(response?.data?.data)
        }
      });
    } catch (error) {
      console.log(error);
    }
  }


  const getCurrentSession = () => {
    setLoading(true)
    try {
      API.Payment.paymentHistory().then((response) => {
        if (response?.status === 200) {
          setPaymentLsit(response?.data?.data_all);
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }


  const handlePayment = (featureName, fetaure) => {
    if (featurePrice?.length) {
      const Feature = featurePrice?.find(feature => feature?.feature_name === featureName);
      navigate(`../${AdminRoute?.Common?.MakePayment}/${fetaure?.features}/?planID=${JSON.stringify(fetaure?._id)}&amount=${JSON.stringify(btoa(Feature?.feature_price))}`)
    }
  }

  const handleUpgradePlan = () => {
    navigate(`../${AdminRoute?.Common?.Plan}?plan=upgrade`)
  }

  return (
    <div className="d-flex h-dashboard profile">
      <Sidebar />
      <div className='toggleIcon'>
        <div className="container-fluid">
          <div className="row">
            <div className="navbar-wrap">
              <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                <h2 className='heading  mt-1 mb-1'>My Payments</h2>
                <Header />
              </nav>
            </div>

            <div className='col-12'>
              <div className="row">
                <p className='text-muted mt-0 mb-2'>Below are your profile details</p>
                <div className="col-lg-8 col-md-12 mb-2">

                  <div className="row h-100">
                    <div className="col-md-12 list-item">
                      <div className="payment-table-height">
                        <div className="dashboard-inner pb-2">
                          <div className="row payment-table">
                            <div className='table-responsive'>
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Order ID</th>
                                    <th scope="col">Feature</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Payment Date</th>
                                    <th scope="col">Expiry Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Loading ?
                                    <div className='mt-3 w-100 mx-auto'>
                                      <div className="container_loader"></div>
                                    </div>
                                    :
                                    paymenList?.length === 0 ?
                                      <div className="alert alert-warning" role="alert">
                                        Data Not Found
                                      </div>
                                      :
                                      paymenList &&
                                      paymenList?.map((val) => {
                                        return (
                                          <tr key={val?.order_id}>
                                            <th scope="row">{val?.order_id}</th>
                                            <td className='text-center'>{val?.paymentfeatures}</td>
                                            <td className='text-center'>Rs. {val?.amount}/-</td>
                                            <td className='text-center'>{moment(val?.createdAt).format('DD/MM/YYYY')}</td>
                                            <td className='text-center'>{moment(val?.enddate).format('DD/MM/YYYY')}</td>
                                          </tr>
                                        )
                                      })
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>{/* col-lg-9 */}


                <div className="col-lg-4 col-md-12  mb-2">
                  <div className="update-subscription h-100">
                    <div className="h-100">
                      <p className='secheading mb-1 mt-0'>My Current Plan</p>
                      <div className='plan-box'>
                        <div className="pricing-card h-100">
                          <div className="pricing-card-heading pricing-card-heading-pro p-4">
                            <h3 className="black-text">{PlanDeatils?.name}</h3>
                            <h3 className="black-text">Feature</h3>
                            <p className='mb-0'>Monthly subscription:</p>
                          </div>
                          <h5 className="mt-3 text-start features-heading">Features</h5>
                          <ul className="pricing-card-list">
                            {PlanDeatils?.feature?.length && Object.entries(PlanDeatils?.feature[0]).map(([key, value], index) => {
                              return (
                                <li key={index} className={`pricing-card-list-item ${value ? "icon-yes" : "icon-no"}`}>{key}</li>
                              )
                            })
                            }
                            {/*
                <li li className="pricing-card-list-item icon-no">+ 4sessions: 1-1 with Therapist</li>
                <li className="pricing-card-list-item icon-no">Join 2 Groups</li>
                <li className="pricing-card-list-item icon-no">Al Therapist</li>
                <li className="pricing-card-list-item icon-no">Digital Notes</li>
                <li className="pricing-card-list-item icon-yes">1 Themes: Light</li>
                <li className="pricing-card-list-item icon-yes"> Playground</li>
              */}
                          </ul>
                          <div className="bg-white d-block px-4 pb-4">
                            <Button type="submit" className="button_common m-0"
                            //  disabled={PlanDeatils?.name === "PRO Plan" ? true : false}
                              onClick={() => handleUpgradePlan()}><span className="text-white">Upgrade Plan</span></Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{/* col-lg- */}
              </div>
            </div>

            <div className='col-12'>
              <div className="row mb-2 payment-box">
                <h3 className='secheading mt-3 mb-3'>Unlock Features</h3>
                <div className="col-md-12 col-lg-3 mb-2 mb-md-0">
                  <div className="dashboard-inner p-2 h-100">
                    <div className="dashboard-img-div">
                      <div className="img">
                        <img src={PlayGround} alt="Playground" />
                      </div>
                    </div>

                    <div className="dashboard-text-div yellow-gradient">
                      <h3 className='secheading mt-3'>Playground</h3>
                      <p className="mb-3">Unleash your creativity and recharge your mind with playful exercises designed for peak performance.</p>
                      {(CurrentPlan.find(item => item.features === "Playground")?.is_available !== 1 && checkDate(new Date(CurrentPlan.find(item => item.features === "Playground")?.endDate))) ?
                        <Button type="submit" className="button_common green-bg" onClick={() => handlePayment("Playground", CurrentPlan.find(item => item.features === "Playground"))}>
                          <span>
                            <svg width="24" color='#fff' height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="5.73685" y="12.0526" width="12.5263" height="8.94737" rx="2" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M16.4737 8.47368C16.4737 6.00294 14.4707 4 12 4C9.52924 4 7.52631 6.00294 7.52631 8.47368" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                          </span>
                          unlock features
                        </Button>
                        :
                        <p className='text-black fw-bold cursor_pointer' onClick={() => navigate(`../${AdminRoute.Common.Playground}`)}>Know More</p>
                      }
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-lg-3 mb-2 mb-md-0">
                  <div className="dashboard-inner p-2 h-100">
                    <div className="dashboard-img-div">
                      <div className="img">
                        <img src={AiTherapist} alt="AiTherapist" />
                      </div>
                    </div>

                    <div className="dashboard-text-div">
                      <h3 className='secheading mt-3'> Al Therapist</h3>
                      <p className="mb-3">Chat anytime with your psychological companion - instant, affordable insights for your peace of mind.</p>
                      {(CurrentPlan.find(item => item.features === "Therapist")?.is_available !== 1 && checkDate(new Date(CurrentPlan.find(item => item.features === "Therapist")?.endDate))) ?
                        <Button type="submit" className="button_common green-bg" onClick={() => handlePayment("Therapist", CurrentPlan.find(item => item.features === "Therapist"))}>
                          <span>
                            <svg width="24" color='#fff' height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="5.73685" y="12.0526" width="12.5263" height="8.94737" rx="2" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M16.4737 8.47368C16.4737 6.00294 14.4707 4 12 4C9.52924 4 7.52631 6.00294 7.52631 8.47368" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                          </span>
                          unlock features
                        </Button>
                        :
                        <p className='text-black fw-bold cursor_pointer' onClick={() => navigate(`../${AdminRoute.Common.Aitherapist}`)}>Know More</p>
                      }
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-lg-3 mb-2 mb-md-0">
                  <div className="dashboard-inner p-2 h-100">
                    <div className="dashboard-img-div">
                      <div className="img">
                        <img src={Groups} alt="Groups" />
                      </div>
                    </div>

                    <div className="dashboard-text-div">
                      <h3 className='secheading mt-3'>Groups</h3>
                      <p className="mb-3">Find your tribe, share challenges, and unlock strength with other driven professionals.</p>
                      {(CurrentPlan.find(item => item.features === "Groups")?.is_available !== 1 && checkDate(new Date(CurrentPlan.find(item => item.features === "Groups")?.endDate))) ?
                        <Button type="submit" className="button_common green-bg" onClick={() => handlePayment("Groups", CurrentPlan.find(item => item.features === "Groups"))}>
                          <span>
                            <svg width="24" color='#fff' height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="5.73685" y="12.0526" width="12.5263" height="8.94737" rx="2" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M16.4737 8.47368C16.4737 6.00294 14.4707 4 12 4C9.52924 4 7.52631 6.00294 7.52631 8.47368" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                          </span>
                          unlock features
                        </Button>
                        :
                        <p className='text-black fw-bold cursor_pointer' onClick={() => navigate(`../${AdminRoute.Common.Join_Group}`)}>Know More</p>
                      }
                    </div>

                  </div>
                </div>

                <div className="col-md-12 col-lg-3">
                  <div className="dashboard-inner p-2 h-100">
                    <div className="dashboard-img-div">
                      <div className="img">
                        <img src={DigitalNotes} alt="Digital Notes" />
                      </div>
                    </div>

                    <div className="dashboard-text-div">
                      <h3 className='secheading mt-3'>Digital Notes</h3>
                      <p className="mb-3">Track your progress and master your mental fitness with a personalized journaling tool and mood tracker.</p>
                      {(CurrentPlan.find(item => item.features === "Notes")?.is_available !== 1 && checkDate(new Date(CurrentPlan.find(item => item.features === "Notes")?.endDate))) ?
                        <Button type="submit" className="button_common green-bg" onClick={() => handlePayment("Notes", CurrentPlan.find(item => item.features === "Notes"))}>
                          <span>
                            <svg width="24" color='#fff' height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="5.73685" y="12.0526" width="12.5263" height="8.94737" rx="2" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M16.4737 8.47368C16.4737 6.00294 14.4707 4 12 4C9.52924 4 7.52631 6.00294 7.52631 8.47368" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                          </span>
                          unlock features
                        </Button>
                        :
                        <p className='text-black fw-bold cursor_pointer' onClick={() => navigate(`../${AdminRoute.Common.DigitalNotes}`)}>Know More</p>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div >

  );
}

export default Payment;
