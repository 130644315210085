import React from 'react';
import ModalBg from "../Assets/Images/DigitalNotes/Theme_popup_BG.png";

const ThemeModal = ({ isOpen, onClose, title, themeList, onThemeSelect,showNotification }) => {
  if (!isOpen) return null;

  const handleThemeClick = (theme) => {
    onThemeSelect(theme); 
    showNotification(); 
    onClose();  
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div
        className="bg-white rounded-lg p-6 w-11/12 max-w-lg relative flex flex-grow justify-center items-center flex-col"
        style={{
          background: `url(${ModalBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <button
          className="absolute top-2 right-2 text-2xl font-bold text-gray-600 hover:text-gray-800"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-3xl font-semibold mb-4 mt-2 text-[#083A50]">{title}</h2>
        {themeList &&
          <div className='grid grid-cols-1 md:grid-cols-2 gap-10 mb-4'>
            {themeList.length > 0 && themeList.map((theme, index) => (
              <div key={index} className='flex flex-col flex-grow justify-center items-center' onClick={() => handleThemeClick(theme)}>
                <img src={theme.backgroundimageurl} className='h-32 w-32 object-cover rounded-lg shadow-md' alt={theme.themeName} />
                <span className='text-xs mt-2 text-black'>{theme.themeName}</span>
              </div>
            ))
            }
          </div>
        }
      </div>
    </div>
  );
};

export default ThemeModal;
