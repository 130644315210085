import React from 'react'

const SCard = ({ SessionDetails, onClick }) => {

    const date = new Date(SessionDetails.timeend);
    const time = date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
    const day = date.toLocaleDateString("en-US", { weekday: "short" });
    const dayOfMonth = date.getDate();
    const month = date.toLocaleDateString("en-US", { month: "short" })
    return (
        <div class="w-full p-3 bg-[#FBFAF5]  rounded-2xl shadow-md hover:bg-gray-100 mb-4 border border-[#CBD5E1]">
            <div className='flex w-full justify-between items-center'>
                <div class="w-20 h-24 rounded-2xl border shadow-lg overflow-hidden text-center bg-white">
                    <div class="bg-blue-500 text-white font-bold py-1">
                        {month}
                    </div>
                    <div class="flex flex-col items-center justify-center flex-1 pt-3 text-black">
                        <p class="text-2xl font-bold" style={{ lineHeight: '2px' }}>{dayOfMonth}</p>
                        <p class="text-sm text-gray-500">{day}</p>
                    </div>
                </div>
                <div className='flex justify-start flex-col flex-grow px-4'>
                    <p class="text-md font-semibold mb-2">Your Upcoming session with {SessionDetails.theropis[0].fullname}</p>
                    <p class="text-sm text-gray-500">{SessionDetails.timestart + ' - ' + time}</p>
                </div>
                <div className='flex justify-center items-center px-2'>
                    <button className="bg-[#F5BD41] text-black py-2 px-4 rounded-lg shadow-md font-semibold align-middle text-center text-sm" onClick={onClick}>
                        Reschedule
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SCard;