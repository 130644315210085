import BaseApi from "./BaseApi";

const API = {
  // auth apis
  Auth: {
    LoginAPIEmail: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/resendotp`,
        data: props.data,
        tokenRequired: false,
      });
    },
    LoginAPIMobile: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/mresendotp`,
        data: props.data,
        tokenRequired: false,
      });
    },
    Register: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/create`,
        data: props.data,
        tokenRequired: false,
      });
    },
    VerifyViaMobile: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/verify/otpphon`,
        data: props.data,
        tokenRequired: false,
      });
    },
    VerifyViaMail: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/verify/otpmail`,
        data: props.data,
        tokenRequired: true,
      });
    },
    Theme: () => {
      return BaseApi({
        method: "GET",
        url: `theme/themelist`,
        tokenRequired: true,
      });
    },
    SelectThemeById: (props) => {
      return BaseApi({
        method: "PUT",
        url: `users/${props.UserId}`,
        data: props.data,
        tokenRequired: true,
      });
    },

    // digital Notes api
    // digital notes list api
    DigitalNotesList: () => {
      return BaseApi({
        method: "GET",
        url: `notes/notelist`,
        tokenRequired: true,
      });
    },
    // digital notes list details api

    DigitalNotesDetails: (props) => {
      return BaseApi({
        method: "GET",
        url: `notes/notelist/${props.notes_id}`,
        tokenRequired: true,
      });
    },

    // update user profile api
    UpdateProfileAPI: (props) => {
      return BaseApi({
        method: "PUT",
        url: `users/${props.UserId}`,
        data: props.data,
        tokenRequired: true,
      });
    },

    //api  call for side bar info on after sign in page
    InfoInfoListAPI: (props) => {
      return BaseApi({
        method: "Get",
        url: `info/infolist?key=${props.helpus}`,
        tokenRequired: false,
      });
    },

    //api  call for Plan list
    PlanPlanListAPI: () => {
      return BaseApi({
        method: "GET",
        url: `plan/planlist`,
        tokenRequired: false,
      });
    },

    //PlayGround API call
    // Mental Health Resources
    Mental_HealthAPI: (props) => {
      return BaseApi({
        method: "GET",
        url: `info/infolist?key=${props.health}`,
        tokenRequired: false,
      });
    },

    // Videos
    Videos_API: (props) => {
      return BaseApi({
        method: "GET",
        url: `/info/infolist?key=${props.kind}`,
        tokenRequired: false,
      });
    },

    // Self Assessment
    SelfAsses_API: (props) => {
      return BaseApi({
        method: "GET",
        url: `/info/infolist?key=${props.selfAsses}`,
        tokenRequired: false,
      });
    },

    //Group apis

    // Group Support
    Support_API: (props) => {
      return BaseApi({
        method: "GET",
        url: `/groups/grouplist?key=${props.support}&name=${
          props.serchKey ? props.serchKey : ""
        }&ingroup=${props.ingroup ? props.ingroup : ""}`,
        tokenRequired: true,
      });
    },
    Support_APIAll: (props) => {
      return BaseApi({
        method: "GET",
        url: `/groups/grouplistall?key=${props.support}&filtertype=${
          props.serchBy ? props.serchBy : ""
        }&name=${props.serchKey ? props.serchKey : ""}`,
        tokenRequired: true,
      });
    },
    Focussed_API: (props) => {
      return BaseApi({
        method: "GET",
        url: `/groups/grouplist?key=${props.focused}&name=${
          props.serchKey ? props.serchKey : ""
        }&ingroup=${props.ingroup ? props.ingroup : ""}`,
        tokenRequired: true,
      });
    },
    //therpist list
    TherpistList_API: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/theropist`,
        tokenRequired: true,
      });
    },
    GetProfileData: () => {
      return BaseApi({
        method: "GET",
        url: `users/userinfo`,
        tokenRequired: true,
      });
    },
  },
  Therapist: {
    TherpistAdd: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/theropistAdd`,
        data: props.data,
        tokenRequired: true,
      });
    },
    getTherpistDetails: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/theropistdtls?date=${props.date}&id=${props.id}`,
        tokenRequired: true,
      });
    },
    CreatMessageid: (props) => {
      return BaseApi({
        method: "POST",
        url: `/aiconnect/creatmessageid`,
        data: props.data,
        tokenRequired: true,
      });
    },
    Chatapi: (props) => {
      return BaseApi({
        method: "POST",
        url: `/aiconnect/chatapi`,
        data: props.data,
        tokenRequired: true,
      });
    },
    ChatHistory: (props) => {
      return BaseApi({
        method: "GET",
        url: props.msgId
          ? `/aiconnect/aichatlist?messageid=${props.msgId}`
          : `/aiconnect/aichatlist`,
        tokenRequired: true,
      });
    },
  },
  MyThreapist: {
    ChatList: () => {
      return BaseApi({
        method: "GET",
        url: `/users/messages`,
        tokenRequired: true,
      });
    },
    Aichatlisthistory: () => {
      return BaseApi({
        method: "GET",
        url: `/aiconnect/aichatlisthistory`,
        tokenRequired: true,
      });
    },
    eventlistindividual: () => {
      return BaseApi({
        method: "GET",
        url: `/event/eventlistindividual`,
        tokenRequired: true,
      });
    },
    meadialistindividual: (props) => {
      return BaseApi({
        method: "GET",
        url: `/groupmedia/medialistindividual?id=${props.id}`,
        tokenRequired: true,
      });
    },
  },
  Session: {
    CreateSession: (props) => {
      return BaseApi({
        method: "POST",
        url: `/session/createsession`,
        data: props.data,
        tokenRequired: true,
      });
    },
    upcomingSessionList: () => {
      return BaseApi({
        method: "GET",
        url: `/session/upsessionlist`,
        tokenRequired: true,
      });
    },
    sumarySessionList: () => {
      return BaseApi({
        method: "GET",
        url: `/session/sumarysessionlist`,
        tokenRequired: true,
      });
    },
    currentSessionList: () => {
      return BaseApi({
        method: "GET",
        url: `/session/currentsessionlist`,
        tokenRequired: true,
      });
    },
    DeleteSession: (props) => {
      return BaseApi({
        method: "GET",
        url: `/session/flushSession?id=${props.id}`,
        tokenRequired: true,
      });
    },
    Sessiondetails: (props) => {
      return BaseApi({
        method: "GET",
        url: `/session/sessiondetails/${props.id}`,
        tokenRequired: true,
      });
    },
    JoinMeeting: (props) => {
      return BaseApi({
        method: "POST",
        data: props.data,
        url: `/session/joinmeeting`,
        tokenRequired: true,
      });
    },
  },
  Notes: {
    UpdateNotes: (props) => {
      return BaseApi({
        method: "PUT",
        url: `/notes/updatenote/${props.update_id}`,
        data: props.data,
        tokenRequired: true,
      });
    },
  },
  Groups: {
    JoinGroups: (props) => {
      return BaseApi({
        method: "POST",
        url: `/groups/joingroup`,
        data: props.data,
        tokenRequired: true,
      });
    },
    GroupsChatList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/chat/chatlist/${props.group_id}?userid=${props.userId}`,
        tokenRequired: true,
      });
    },
    EventList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/event/eventlist?key=${props.group_id}`,
        tokenRequired: true,
      });
    },
    Medialist: (props) => {
      return BaseApi({
        method: "GET",
        url: `/groupmedia/medialist?key=${props.group_id}`,
        tokenRequired: true,
      });
    },
    AnswerEvent: (props) => {
      return BaseApi({
        method: "POST",
        url: `/event/answerevent`,
        data: props.data,
        tokenRequired: true,
      });
    },
    Groupcount: (props) => {
      return BaseApi({
        method: "POST",
        url: `/groups/groupcount`,
        data: props.data,
        tokenRequired: true,
      });
    },
  },
  Survey: {
    Surveylist: (props) => {
      return BaseApi({
        method: "GET",
        url: `/survaytype/survaylist?title=${props.title}`,
        tokenRequired: true,
      });
    },
    AnswerEvent: (props) => {
      return BaseApi({
        method: "POST",
        url: `/event/answerevent`,
        data: props.data,
        tokenRequired: true,
      });
    },
    SubmitSurvay: (props) => {
      return BaseApi({
        method: "POST",
        url: `/survaytype/answersurvey`,
        data: props.data,
        tokenRequired: true,
      });
    },
    AnswerSurvey: () => {
      return BaseApi({
        method: "GET",
        url: `/survaytype/answersurvey`,
        tokenRequired: true,
      });
    },
    Wellness: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/welness`,
        data: props.data,
        tokenRequired: true,
      });
    },
    WellnessTagsList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/welnessTags`,
        tokenRequired: true,
      });
    },
    InsertIndividualWellnessTags: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/insertIndividualWellnessTags`,
        data: props.data,
        tokenRequired: true,
      });
    },
    getIndividualWellnessTags: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/getIndividualWellnessTags`,
        data: props.data,
        tokenRequired: true,
      });
    },
    MoodTrackerStatus: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/moodstatus`,
        data: props.data,
        tokenRequired: true,
      });
    },
    GetTimit: () => {
      return BaseApi({
        method: "GET",
        url: `/survaytype/getlimit`,
        tokenRequired: true,
      });
    },
  },
  Payment: {
    RezorPayGetId: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/userpay`,
        data: props.data,
        tokenRequired: true,
      });
    },
    ChackPlanAllow: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/chackplanallow`,
        data: props.data,
        tokenRequired: true,
      });
    },
    Paymentupdate: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/paymentupdate`,
        data: props.data,
        tokenRequired: true,
      });
    },
    Adduserplan: (props) => {
      return BaseApi({
        method: "POST",
        url: `/plan/adduserplan`,
        data: props.data,
        tokenRequired: true,
      });
    },
    MyPlanlfeatures: (props) => {
      return BaseApi({
        method: "GET",
        url: `/plan/planlfeatures?userType=${props.data}`,
        tokenRequired: true,
      });
    },
    FeaturePriceList: () => {
      return BaseApi({
        method: "GET",
        url: `/users/featurepricelist`,
        tokenRequired: true,
      });
    },
    paymentHistory: () => {
      return BaseApi({
        method: "GET",
        url: `/users/paymenthistory`,
        tokenRequired: true,
      });
    },
  },
  NotificationApis: {
    getAllnotificationApi: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/notification`,
        tokenRequired: true,
      });
    },
  },
  WorkshopApis: {
    getAllWorkshop: (props) => {
      return BaseApi({
        method: "GET",
        url: `/workshop/getWorkshops`,
        tokenRequired: true,
      });
    },
  },
  ResheduleLimit: {
    LimitSessionReshedule: (props) => {
      return BaseApi({
        method: "POST",
        url: `/reshedule/resheduleLog`, // tbd
        data: props.data,
        tokenRequired: true,
      });
    },
    getResheduledData: (props) => {
      return BaseApi({
        method: "POST",
        url: `/reshedule/getresheduleLog`, // tbd
        data: props.data,
        tokenRequired: true,
      });
    },
  },
};

export default API;
