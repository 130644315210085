// App.js
import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import AdminRoute from "../../Route/RouteDetails"
import WellBeingtable from "../Common/WellBeingtable";
import API from "../../Api/Api";
function WllbeingTest() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [allResourceData, setAllResourceData] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [loadingList, setLoadingList] = useState(false);
    const [error, setError] = useState("");


    useEffect(() => {
        getsuravayDetails();
    }, []);

    const getsuravayDetails = () => {
        setLoadingList(true)
        try {
            API.Survey.Surveylist({
                title: "well",
            }).then((response) => {
                if (response.data.status === 1) {
                    setAllResourceData(response?.data?.data_all[0]);
                    const trueAnswerIdAndValue = {};
                    response?.data?.data_all[0].subsurvay?.forEach((question) => {
                        const trueAnswer = question.answers.find((answer) => Object.values(answer)[0] === true);

                        if (trueAnswer) {
                            trueAnswerIdAndValue[question._id] = Object.keys(trueAnswer)[0];
                        }
                    });
                    setSelectedAnswers(trueAnswerIdAndValue)
                }
                setLoadingList(false)
            });
        } catch (error) {
            console.log(error);
            setLoadingList(false)
        }
    };

    const handleSave = () => {
        // Check if all checkboxes are selected
        const areAllCheckboxesSelected = Object.keys(selectedAnswers).length === allResourceData.subsurvay.length;
        if (!areAllCheckboxesSelected) {
            // Show an alert or take any other action to inform the user that all checkboxes must be selected
            setError('Please select an answer for each question before submitting.');
            return;
        } else {
            setError("")
        }


        const newData = allResourceData.subsurvay.map(({ _id, ...rest }) => rest);

        API.Survey.SubmitSurvay({
            data: {
                data: newData,
                isupdate: allResourceData?.isupdate,
                survayType: "wellbeing"
            }
        })
            .then((response) => {
                if (response?.data?.status === 1) {
                    if (response?.data?.status === 1) {
                        handleShow()
                        setError("")
                        setTimeout(() => {
                            handleClose()
                            navigate(`..${AdminRoute?.Common?.Profile}`);
                        }, 2000)
                    }
                } else {
                    setError(response?.data?.message)
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    return (
        <div className="d-flex h-dashboard profile">
            <Sidebar />
            <div className="toggleIcon">
                <div className="container-fluid">
                    <div className="row">
                        <div className="nav-wrap">
                            <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                                <h2 className="heading  mt-1 mb-1" onClick={() => navigate(-1)}>
                                    <span className="arrow arrow-left"></span>
                                    Well being Test
                                </h2>
                                <Header />
                            </nav>
                        </div>
                        <div className="col-12">
                            <p>Here is an overview of your session.</p>
                            <p>
                                Please answer the following questions, which are about how have
                                you been feeling for the past month.
                            </p>
                            <p>
                                Place a check mark on the box that best represents how often
                                have you experienced or felt the following:
                            </p>
                            <div className="welltest-table mt-4 mb-4">
                                <WellBeingtable
                                    allResourceData={allResourceData}
                                    setAllResourceData={setAllResourceData}
                                    selectedAnswers={selectedAnswers}
                                    setSelectedAnswers={setSelectedAnswers}
                                    loadingList={loadingList}
                                />
                            </div>
                            <div className="col-md-12 mb-4">
                                <p className="text-danger">{error}</p>
                                <Button
                                    variant="primary"
                                    onClick={() => handleSave()}
                                    className="mb-4 button_common m-0 mb-2"
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
            <Modal show={show} onHide={handleClose} className="payment-model">
                <Modal.Body>
                    <div className="item m-auto">
                        <div className="tick-mark-div"> </div>
                        <h2 className="heading text-center mb-1">
                            Response recorded successfully
                        </h2>
                        {/*
                    <p className="text-center">
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's{" "}
                        </p>
                    */}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default WllbeingTest;
