import React from 'react';
import { NavLink } from "react-router-dom";
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import AdminRoute from '../../Route/RouteDetails';
import { useNavigate } from "react-router-dom";

function JoinGroupsession() {  
    const navigate = useNavigate();
    
    const renderActionButton = () => {
        // return joined ? (
        //     <button onClick={handleLeaveClick}>Leave</button>
        // ) : (
        //     <button onClick={handleJoinClick}>Join</button>
        // );
        navigate(`../${AdminRoute?.Common?.VideoCall}`)
    };
        return (
            <div className="d-flex h-dashboard conversation-page list">
                <Sidebar />
                <div className='toggleIcon'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="row">
                                <nav className="navbar navbar-expand-lg header-navbar p-0">
                                    <div className='w-100 h-head'>
                                        <h2 className='heading  mt-1 mb-1'>My Groups</h2>
                                        <Button type="submit" className="create-new_notes button_common notes-btn transparent-btn"><NavLink to={AdminRoute?.Common?.Join_Group}>Join Groups </NavLink></Button>
                                    </div>
                                    <Header />
                                </nav>
                                <div><p className='mb-2 gray-text'>Here is list of all your focussed and support groups.</p></div>
                            </div>
                            <div className="col-lg-3 col-md-5 col-sm-5 mb-2 list-item">
                                <div className="dashboard-inner chat-left-div bg-pink shadow-pink">
                                    <div className="sessions-tabs chat-tabs mt-2">
                                        <Tabs
                                            defaultActiveKey="profile"
                                            id="uncontrolled-tab-example"
                                            className="mb-3 p-0"
                                        >
                                            <Tab eventkey="home" title="Focussed">
                                                <div className="p-0 clearfix row sessions-text">
                                                    <div className="people-list" id="people-list">
                                                        <div className="search mb-3">

                                                            <input type="text" placeholder="search" />

                                                        </div>
                                                        <ul className="people-members-list">
                                                            <li className="clearfix">
                                                                <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01.jpg" alt="avatar" />
                                                                <div className="about">
                                                                    <div className="name">Fight Anxiety</div>
                                                                    <span className="members">Members: 10/20</span>

                                                                </div>
                                                                <div className="status">
                                                                    <div className="day">Yesterday</div>
                                                                    <span className="circle">6</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>




                                            </Tab> {/* end Focussed-tab */}

                                            <Tab eventkey="profile" title="Support">
                                                <div className="row sessions-text p-0 clearfix">
                                                    <div className="people-list" id="people-list">
                                                        <div className="search mb-3">
                                                            <input type="text" placeholder="search" />

                                                        </div>
                                                        <ul className="people-members-list">
                                                            <li className="clearfix">
                                                                <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01.jpg" alt="avatar" />
                                                                <div className="about">
                                                                    <div className="name">Fight Anxiety</div>
                                                                    <span className="members">Members: 10/20</span>

                                                                </div>
                                                                <div className="status">
                                                                    <div className="day">Yesterday</div>
                                                                    <span className="circle">4</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Tab> {/* end Support-tab */}
                                        </Tabs>
                                    </div>
                                </div>
                            </div> {/* end col-lg-3  */}

                            <div className="col-lg-9 col-md-7 col-sm-7 mb-2 list-item">
                                <div className="dashboard-inner">
                                    <div className='chat'>
                                        <div className="chat-header bg-pink clearfix">
                                            <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg" alt="avatar" />

                                            <div className="chat-about">
                                                <div className="chat-with secheading">Fight Anxiety Discussion Forum</div>
                                                <div className="chat-num-messages">This group expires on 28 August, 2023 (in 2 days)</div>
                                            </div>
                                            <div className="button"><Button type="submit" onClick = {()=>renderActionButton()}className="button_common m-0 p-2">Join Group Session</Button></div>
                                        </div>
                                    </div>
                                    <div className="ai-div">
                                        <div className="therapist-content-center">
                                            <div className="call-img">
                                                <img alt="pic" className="call-pic mb-4" src="https://miro.medium.com/v2/resize:fill:80:80/4*f7EyGRullh3Ih_2tm3k5xw.png" width="164" height="164" loading="lazy" />
                                            </div>
                                            <h2>Fight Anxiety</h2>
                                            <p>calling</p>
                                        </div>

                                    </div>

                                </div>
                            </div>{/* end col-lg-9 */}
                        </div>
                        <Footer />
                    </div>{/* end conversation-page */}
                </div>
            </div>
        );
        }
export default JoinGroupsession;
