import React from 'react';


const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="rounded-lg w-[90%] h-[95%] max-w-[90%] relative overflow-hidden flex flex-col py-12 px-4 bg-white">
        <button
          className="absolute top-2 right-2 text-2xl font-bold text-gray-700 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="flex-1 w-full h-full">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
