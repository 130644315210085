import React from 'react';
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa6";
import { FaRupeeSign } from "react-icons/fa";

const WorkshopCard = ({ data }) => {
    const date = new Date(data.eventDate);
    const formattedDate = new Intl.DateTimeFormat('en-US', {
        weekday: 'long',
        day: '2-digit',
        month: 'long',
    }).format(date);
    return (
        <div class="max-w-[400px] rounded-3xl overflow-hidden shadow-md bg-white">
            <img class="w-full" src={data.eventImage} alt="Event Image" />
            <div className='flex px-4 py-2'>
                <div className='flex-grow'>
                    <div class="pb-2">
                        <div class="font-bold text-xl mb-2">{data.eventName}</div>
                        <p class="text-gray-700 text-base">
                            {data.eventDescription.length > 20 ? data.eventDescription.slice(0, 20) + '...' : data.eventDescription}
                        </p>
                    </div>
                    <div class="mb-4">
                        <div class="text-sm mb-2 flex text-gray-700 "><FaRegCalendarAlt className='mr-2' size={18} />{formattedDate}</div>
                        <div class=" text-sm mb-2 flex text-gray-700"><FaRegClock className='mr-2' size={18} />{data.eventStartTime}{" - "}{data.eventEndTime} </div>
                    </div>
                </div>

                <div className='w-1/3 flex flex-col justify-center items-center h-full'>
                    <div class="text-[35px] font-bold  mt-4 flex items-center text-[#28BA82]">
                        <FaRupeeSign className="mr-1" size={30} />
                        {data.amount}
                    </div>

                    <button className='bg-[#FFB81D] px-4 mt-4 py-2 text-black text-sm rounded-lg shadow-lg '>
                        Register
                    </button>
                </div>
            </div>

        </div>
    )
}

export default WorkshopCard