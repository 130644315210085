// App.js
import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import API from "../../Api/Api";
import AdminRoute from "../../Route/RouteDetails";

function PersonalityTests() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [allResourceData, setAllResourceData] = useState([]);
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [loadingList, setLoadingList] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    getsuravayDetails();
  }, []);

  const getsuravayDetails = () => {
    setLoadingList(true);
    try {
      API.Survey.Surveylist({
        title: "personality",
      }).then((response) => {
        if (response?.data?.status === 1) {
          setAllResourceData(response?.data?.data_all[0]);
          const trueAnswerIdAndValue = {};
          response?.data?.data_all[0].subsurvay?.forEach((question) => {
            const trueAnswer = question.answers.find(
              (answer) => Object.values(answer)[0] === true
            );
            if (trueAnswer) {
              trueAnswerIdAndValue[question._id] = Object.keys(trueAnswer)[0];
            }
          });
          setSelectedAnswers(trueAnswerIdAndValue);
        }
        setLoadingList(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingList(false);
    }
  };

  const handleAnswer = (
    e,
    ele,
    eleIndex,
    questionIndex,
    questionId,
    answerText
  ) => {
    //obj for comparing
    if (e.target.checked) {
      setSelectedAnswers((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: answerText,
      }));
    } else {
      setSelectedAnswers(delete selectedAnswers[questionId]);
    }

    // get selected key
    let key1 = Object.keys(
      allResourceData?.subsurvay[eleIndex].answers[questionIndex]
    )[0];
    setAllResourceData({
      ...allResourceData, // Copy the original object
      subsurvay: allResourceData?.subsurvay?.map((question, index) => {
        if (eleIndex === index) {
          return {
            ...question, // Copy the original question object
            answers: question?.answers?.map((answer, i) => {
              const key = Object?.keys(answer)[0];
              answer[key] = false; // set all false
              // Check a condition before updating the specific property
              if (i === questionIndex) {
                return {
                  ...answer,
                  [key1]: e.target.checked, // Update to the desired value
                };
              } else {
                return answer; // if not change return all same
              }
            }),
            created_by: UserData?.userdata?._id,
          };
        }
        return question;
      }),
    });
  };

  const handleSave = () => {
    // Check if all checkboxes are selected
    const areAllCheckboxesSelected =
      Object.keys(selectedAnswers).length === allResourceData?.subsurvay?.length;

    if (!areAllCheckboxesSelected) {
      // Show an alert or take any other action to inform the user that all checkboxes must be selected
      setError("Please select an answer for each question before submitting.");
      return;
    } else {
      setError("");
    }

    const newData = allResourceData?.subsurvay?.map(({ _id, ...rest }) => rest);
    API.Survey.SubmitSurvay({
      data: {
        data: newData,
        isupdate: allResourceData?.isupdate,
        survayType: "personality",
      },
    })
      .then((response) => {
        if (response?.data?.status === 1) {
          // Handle successful submission
          handleShow();
          setError("");
          setTimeout(() => {
            handleClose();
            navigate(`..${AdminRoute?.Common?.Profile}`);
          }, 2000);
        } else {
          setError(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="d-flex h-dashboard profile">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row">
            <div className="nav-wrap">
              <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                <h2 className="heading  mt-1 mb-1" onClick={() => navigate(-1)}>
                  <span className="arrow arrow-left"></span>
                  Personality Tests
                </h2>
                <Header />
              </nav>
            </div>
            <div className="col-12">
              <div className="row">
                <p>Here is an overview of your session.</p>
                {loadingList ? null : allResourceData.length === 0 ? (
                  <div className="alert alert-warning" role="alert">
                    Data Not Found
                  </div>
                ) : (
                  allResourceData &&
                  allResourceData?.subsurvay?.map((ele, index) => {
                    return (
                      <div
                        className="col-lg-4 col-md-6 col-sm-12 pb-4"
                        key={ele._id}
                      >
                        <div className="grey-div mb-4 h-100">
                          <p className="mb-2">
                            {index + 1}. {ele.question}
                          </p>
                          <div className="bg-pink grey-div-bg h-100">
                            {ele.answers.map((val, i) => {
                              const isChecked =
                                selectedAnswers[ele._id] ===
                                Object.keys(val)[0];
                              return (
                                <div className="custom-control custom-checkbox d-flex align-items-baseline">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customCheck1"
                                    name={Object.keys(val)[0]}
                                    checked={isChecked}
                                    onChange={(e) =>
                                      handleAnswer(
                                        e,
                                        ele,
                                        index,
                                        i,
                                        ele._id,
                                        Object.keys(val)[0]
                                      )
                                    }
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck1"
                                  >
                                    {Object.keys(val)[0]}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
                <div className="col-md-12 mb-4">
                  <p className="text-danger">{error}</p>
                  <Button
                    variant="primary"
                    onClick={() => handleSave()}
                    className="button_common m-0 mb-2"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="payment-model">
        <Modal.Body>
          <div className="item m-auto">
            <div className="tick-mark-div"> </div>
            <h2 className="heading text-center mb-1">
              Response recorded successfully
            </h2>
            {/*
                        <p className="text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p>

*/}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PersonalityTests;
