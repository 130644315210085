import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import { ArrowLeft } from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import API from "../../Api/Api";
import AdminRoute from "../../Route/RouteDetails";
import baseApi from "../../Api/config";
import plusIcon from "../../Assets/Images/Icon feather-plus.png";


const UserProfile = () => {
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
  const initialValues = {
    firstName: "",
    lastName: "",
    birthDate: "",
    profileImage: ""
  };
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    if (UserData.userdata) {
      setFormData({
        ...formData,
        firstName: UserData.userdata.name,
        lastName: UserData.userdata.last_name,
        birthDate: UserData.userdata.dob.split("T")[0],
        profileImage: UserData.userdata.image
      })
    }
  }, [])


  const Update_userValidate = (values) => {
    const errors = {};
    if (!values?.firstName) {
      errors.firstName = "First name is required";
    }
    if (!values?.lastName) {
      errors.lastName = "Last name is required";
    }
    if (!values?.birthDate) {
      errors.birthDate = "Birth date is required";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // API.
      try {
        API.Auth.UpdateProfileAPI({
          data: {
            name: formData?.firstName,
            last_name: formData?.lastName,
            dob: formData?.birthDate,
            // email: formData?.email,
            role: "Client",
            image: formData?.profileImage
          },
          UserId: UserData?.userdata?._id,


        }).then((response) => {
          UserData.userdata.name = formData.firstName
          UserData.userdata.last_name = formData?.lastName
          UserData.userdata.dob = formData?.birthDate
          UserData.userdata.image = formData?.profileImage
          if (response?.data?.message === "Profile successfully updated.") {
            navigate(`../${AdminRoute?.Common?.Profile}`);
            localStorage.setItem(
              "ClientUserData",
              JSON.stringify(UserData)
            );
          }
          setIsSubmit(false)
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [formErrors]);


  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(Update_userValidate(formData));
    setIsSubmit(true);
  };

  const disableFutureDate = () => {
    const today = new Date().toISOString().split('T')[0];
    // const dd = String(today.getDate()).padStart(2, "0");
    // const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    // const yyyy = today.getFullYear();
    return today;
  }


  const handleUpload = (e) => {
    // console.log(e.target.files[0]);
    // if (
    //   e.target.files[0].type.includes("image") &&
    //   e.target.files[0].size < 4000000
    // )
    setSpinner(true);
    try {
      var formdata = new FormData();
      formdata.append("image", e.target.files[0], e.target.files[0].name);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseApi.baseurl}fileupload/image`, requestOptions)
        .then((response) => response.text())
        .then(
          (result) => addImage(JSON.parse(result).data?.filename),
          setSpinner(false)
        );
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };


  const addImage = (image) => {
    setFormData({
      ...formData,
      profileImage: `${baseApi.baseurl}images/${image}`
    })
  };

  return (
    <>
      <div className="container-fluid p-0 bg-grey userprofile">
        <div className="main-container w-100 pb-0">
          <div className="col-sm-12">
            <div className="register bg-pink m-auto edit-profile-v-height">
              <h2 className="heading"><ArrowLeft className="left-arrow cursor-pointer" size={16} onClick={() => navigate(-1)} />Edit Profile</h2>
              <form >
                {spinner ?
                  <span>loading</span>
                  :
                  <>
                    {formData?.profileImage ?
                      <span className="custom-drag-box mx-auto row">
                        <div className="d-flex flex-column align-items-center w-100 h-100">
                          <i
                            className="fa fa-2x fa-check-circle me-2 text-orange text-20px d-none"
                            aria-hidden="true"
                          ></i>
                          <div
                            className="form-upload-photo-preview2"
                            style={{
                              backgroundImage: `url(${formData?.profileImage})`,
                            }}
                          ></div>
                          <span className="custom-drag-box-text d-none">
                            Image Uploaded
                          </span>
                          <input
                            type="file"
                            id="uploadFile"
                            accept="image/png, image/gif, image/jpeg, image/jpg"
                            tabIndex="1"
                            title=" "
                            onChange={(e) => handleUpload(e)}
                          />
                        </div>
                      </span>
                      :
                      <div className="d-flex justify-content-center ">
                        <span className="custom-drag-box">
                          <img className="group-img" src={plusIcon} alt="plus" />
                          <input
                            type="file"
                            id="uploadFile"
                            accept="image/png, image/gif, image/jpeg"
                            tabIndex="1"
                            title=" "
                            onChange={(e) => handleUpload(e)}
                          />
                        </span>
                      </div>
                    }
                  </>
                }
                <div>
                  <label>First Name:</label>
                  <input
                    type="text"
                    className="bg-white"
                    name="firstName"
                    autoComplete="off"
                    placeholder="Enter First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label>Last Name:</label>
                  <input
                    type="text"
                    className="bg-white"
                    placeholder="Enter Last Name"
                    name="lastName"
                    autoComplete="off"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label>BirthDate:</label>
                  <input
                    type="date"
                    className="bg-white"
                    placeholder="Enter BirthDate"
                    name="birthDate"
                    value={formData?.birthDate}
                    autoComplete="off"
                    max={disableFutureDate()}
                    onChange={handleChange}
                    required
                  />
                </div>
                <Button type="submit" className="button_common"
                  onClick={(e) => handleSubmit(e)}
                  disabled={isSubmit}
                >
                  Save
                </Button>

              </form>
            </div>
          </div>{/* col-md-6 */}
        </div >
      </div >
       {/* end of container */}
    </>
  );
};
export default UserProfile;
