import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import { toast } from "react-toastify";
import LoginPageBg from "../../Assets/Images/Login/loginBg.png";
import logo from "../../Assets/Images/Login/ryteLife-full.png";
import { PlanContext } from "../../ContextApi/PlanContext";


const Login = () => {
  const navigate = useNavigate();
  const initialValues = { InputType: "", clientType: "Client" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  // const [error, setError] = useState("");
  const [ApiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false);
  const [remainingTime, setRemainingTime] = useState(60);
  const [showResend, setShowResend] = useState(false);
  const [otpSent, setOtpSent] = useState(false);

  const { planDetails, setPlanDetails } = useContext(PlanContext);

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    if (remainingTime > 0) {
      const timer = setInterval(() => {
        setRemainingTime((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setShowResend(true);
    }
  }, [remainingTime]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const mobileRegex = /^\d+$/;

  const validate = (values) => {
    const errors = {};

    if (!values?.InputType) {
      errors.InputType = "EmailId or Mobile no is Required!";
    }
    if (mobileRegex.test(!values?.InputType)) {
      errors.InputType = "Enter Valid Mobile Number!";
    }
    if (emailRegex.test(!values?.InputType)) {
      errors.InputType = "Enter Valid Email ID!";
    }
    if (
      mobileRegex.test(values?.InputType) &&
      emailRegex.test(values?.InputType)
    ) {
      errors.InputType = "Invalid Input!";
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (emailRegex.test(formValues?.InputType)) {
        setLoading(true);
        try {
          API.Auth.LoginAPIEmail({
            data: {
              email: formValues?.InputType,
              role: formValues?.clientType ? formValues?.clientType : 'Client',
              formname: "Login",
            },
          }).then((response) => {
            localStorage.setItem("EmailId", formValues?.InputType);
            if (
              response?.data?.status === 1 &&
              response?.data?.message === "The OTP has been sent successfully."
            ) {
              setApiError(response?.data?.message);
              setOtpSent(true);
              // navigate(
              //   `../${AdminRoute?.Auth?.verifyOtpMail}/1?email=${JSON.stringify(
              //     formValues?.InputType
              //   )}`
              // );
            } else if (
              response?.data?.status === 0 &&
              response?.data?.message === "User not registered." &&
              formValues?.clientType === 'Client'
            ) {
              navigate(
                `../${AdminRoute?.Auth?.Register}?email=${JSON.stringify(
                  formValues?.InputType
                )}`
              );
            } else if (
              response?.data?.status === 0 &&
              response?.data?.message === "Please complete the registration."
            ) {
              // ================================================================
              // handleResendOtpEmail(response?.data?.data[0]?.email);    // Testing Remaing because of otp not sent
              // ====================================================================
              localStorage.setItem("Mobileno", response?.data?.data?.phone);

              if (response?.data?.data?.is_verifiedp === true) {
                // handleResendOtpEmail(response?.data?.data?.email);
                // navigate(
                //   `../${AdminRoute?.Auth?.verifyOtpMail
                //   }/2?email=${JSON.stringify(formValues?.InputType)}`
                // );
              } else {
                // handleResendOtp(response?.data?.data?.phone);
                // navigate(
                //   `../${AdminRoute?.Auth?.VerifyOtp
                //   }/2?mobile_no=${JSON.stringify(response?.data?.data?.phone)}`
                // );
              }
              setApiError(response?.data?.message);
            }
            // setApiError(response?.data?.text);
            setLoading(false);
          });
        } catch (error) {
          setApiError(error.message);
          console.log(error);
          setLoading(false);
        }
      }

      if (mobileRegex.test(formValues?.InputType)) {
        setLoading(true);
        try {
          API.Auth.LoginAPIMobile({
            data: {
              phone: formValues?.InputType,
              role: formValues?.clientType ? formValues?.clientType : 'Client',
              formname: "Login",
            },
          }).then((response) => {
            console.log('LoginResponse', response);
            localStorage.setItem("Mobileno", formValues?.InputType);
            if (
              response?.data?.status === 1 &&
              response?.data?.message === "The OTP has been sent successfully."
            ) {
              setOtpSent(true);
              // navigate(
              //   `../${AdminRoute?.Auth?.VerifyOtp}/1?mobile_no=${JSON.stringify(
              //     formValues?.InputType
              //   )}`
              // );
              setApiError(response?.data?.message);
            } else if (
              response?.data?.status === 0 &&
              response?.data?.message === "User not registered." &&
              formValues?.clientType === 'Client'
            ) {
              toast.warning('Kindly Register in order to avail our services');
              navigate(
                `../${AdminRoute?.Auth?.Register}?mobile_no=${JSON.stringify(
                  formValues?.InputType
                )}`
              );
              setApiError(response?.data?.message);
            } else if (
              response?.data?.status === 0 &&
              response?.data?.message === "User not registered." &&
              formValues?.clientType === 'Cmsemployee'
            ) {
              toast.warning('Kindly contact your Admin to avail our services');
              setApiError(response?.data?.message);
            }
            else if (
              response?.data?.status === 0 &&
              response?.data?.message ===
              "Please wait for some time, unable to send message."
            ) {
              toast.error('Please wait for some time, unable to send message')
              setApiError(response?.data?.message);
            } else if (
              response?.data?.status === 0 &&
              response?.data?.message === "Please complete registration."
            ) {
              // ====================================================================
              // handleResendOtp(response?.data?.data?.phone);   // Testing Remaing because of otp not sent
              // ==========================================================================
              localStorage.setItem("EmailId", response?.data?.data[0]?.email);
              if (response?.data?.data[0]?.is_verifiedp === true) {
                handleResendOtpEmail(response?.data?.data[0]?.email);
                // navigate(
                //   `../${AdminRoute?.Auth?.verifyOtpMail
                //   }/2?email=${JSON.stringify(response?.data?.data[0]?.email)}`
                // );
              } else {
                handleResendOtp(response?.data?.data[0]?.phone);
                // navigate(
                //   `../${AdminRoute?.Auth?.VerifyOtp
                //   }/2?mobile_no=${JSON.stringify(formValues?.InputType)}`
                // );
              }

              // setApiError(response?.data?.message);
            }
            // setApiError(response?.data?.message);
            setLoading(false);
          });
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    }
  }, [formErrors]);

  // useEffect(() => {
  //   if (Object.keys(formErrors).length === 0 && isSubmit) {

  //     if (emailRegex.test(formValues?.InputType)) {
  //       API.Auth.LoginAPIEmail({
  //         data: {
  //           email: formValues?.InputType,
  //           role: "Client",
  //           formname: "Login",
  //         },
  //       })
  //         .then((response) => {
  //           if (response?.data?.status === 1) {
  //             navigate(
  //               `../${AdminRoute?.Auth?.verifyOtpMail}/1?email=${JSON.stringify(
  //                 formValues?.InputType
  //               )}`
  //             );
  //             // toast.success(response?.data?.message);
  //             setError("");
  //           } else {
  //             if (response?.data?.message === "User not registered.") {
  //               navigate(
  //                 `../${AdminRoute?.Auth?.Register}?email=${JSON.stringify(
  //                   formValues?.InputType
  //                 )}`
  //               );
  //             } else if (!response?.data?.verifiedboth) {
  //               handleResendOtp(response?.data?.data?.phone);
  //               navigate(
  //                 `../${
  //                   AdminRoute?.Auth?.verifyOtpMail
  //                 }/2?email=${JSON.stringify(
  //                   response?.data?.data?.email
  //                 )}&mobile_no=${JSON.stringify(response?.data?.data?.phone)}`
  //               );
  //             }
  //             // toast.error(response?.data?.message);
  //             setError(response?.data?.message);
  //           }
  //           setError(response?.data?.message);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //       setIsSubmit(false);
  //     }

  //     if (mobileRegex.test(formValues?.InputType)) {
  //       API.Auth.LoginAPIMobile({
  //         data: {
  //           phone: formValues?.InputType,
  //           role: "Client",
  //           formname: "Login",
  //         },
  //       })
  //         .then((response) => {
  //           if (response?.data?.status === 1) {
  //             navigate(
  //               `../${AdminRoute?.Auth?.VerifyOtp}/1?mobile_no=${JSON.stringify(
  //                 formValues?.InputType
  //               )}`
  //             );
  //             // toast.success(response?.data?.message);
  //             setError(response?.data?.message);
  //           } else {
  //             if (response?.data?.message === "User not registered.") {
  //               navigate(
  //                 `../${AdminRoute?.Auth?.Register}?mobile_no=${JSON.stringify(
  //                   formValues?.InputType
  //                 )}`
  //               );
  //             } else if (!response?.data?.verifiedboth) {
  //               setError(response?.data?.message);
  //               handleResendOtpEmail(
  //                 response?.data?.message ===
  //                   "Please wait for some time not able to send message..."
  //                   ? ""
  //                   : response?.data?.data[0]?.email
  //               );
  //               navigate(
  //                 `../${AdminRoute?.Auth?.VerifyOtp}/2?email=${JSON.stringify(
  //                   response?.data?.data[0]?.email
  //                 )}&mobile_no=${JSON.stringify(
  //                   response?.data?.data[0]?.phone
  //                 )}`
  //               );
  //             }
  //             toast.error(response?.data?.message);
  //             setError(response?.data?.message);
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //       setIsSubmit(false);
  //     }
  //   }
  // }, [formErrors]);

  const handleSubmitMobile = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const handleResendOtp = () => {
    try {
      API.Auth.LoginAPIMobile({
        data: {
          phone: formValues?.InputType,
          role: formValues?.clientType ? formValues?.clientType : 'Client',
        },
      }).then((response) => {
        if (response?.data?.status === 1) {
          // toast.success(response?.data?.message);
          setApiError("");
        } else {
          // toast.error(response?.data?.message);
          setApiError(response?.data?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendOtpEmail = () => {
    try {
      API.Auth.LoginAPIEmail({
        data: {
          email: formValues?.InputType,
          role: formValues?.clientType ? formValues?.clientType : 'Client',
        },
      }).then((response) => {
        if (response?.data?.status === 1) {
          // toast.success(response?.data?.message);
          setApiError("");
        } else {
          // toast.error(response?.data?.message);
          setApiError(response?.data?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleVerifyOtp = (e) => {
    e.preventDefault();

    if (emailRegex.test(formValues?.InputType)) {
      try {
        API.Auth.VerifyViaMail({
          data: {
            email: formValues?.InputType,
            otp: formValues?.otp,
            role: formValues?.clientType,
          },
        }).then((response) => {
          if (response?.data?.status === 1) {
            localStorage.setItem("AuthTokenClient", response?.data?.token);
            localStorage.setItem(
              "ClientUserData",
              JSON.stringify(response?.data)
            );
            MyPlanlfeatures();
            navigate(`../${AdminRoute?.Common?.Dashboard}`);
          } else {
            // toast.error(`${response.data.message}`);
            // setError(response?.data?.message);
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else if (mobileRegex.test(formValues?.InputType)) {
      try {
        API.Auth.VerifyViaMobile({
          data: {
            phone: formValues?.InputType,
            otp: formValues?.otp,
            role: formValues?.clientType,
          },
        }).then((response) => {
          if (response?.data?.status === 1) {
            localStorage.setItem("AuthTokenClient", response?.data?.token);
            localStorage.setItem(
              "ClientUserData",
              JSON.stringify(response?.data)
            );
            MyPlanlfeatures();
            navigate(`../${AdminRoute?.Common.Dashboard}`);
          }
          setIsSubmit(false);
        });
      } catch (error) {
        console.log(error);
        setIsSubmit(false);
      }
    }

  };


  const MyPlanlfeatures = () => {
    setLoading(true);
    try {
      API.Payment.MyPlanlfeatures({
        data: formValues?.clientType
      }).then((response) => {
        if (response.status === 200) {
          setPlanDetails(response.data.plandetails);
        }
      });
    } catch (error) {
      false(false);
      console.log(error);
    }
  };


  return (
    <>
      {/* <div className="container login-page">
        <div className="row main-container">
          <div className="col-md-6 col-sm-12 left-text">
            <div className="logo-div">
              <NavLink to="/">
                <img src="/images/logo.png" className="logo" alt="logo" />{" "}
              </NavLink>
            </div>
            <h1 className="heading">Take Charge of Your Mental Well-Being!</h1>
            <p className="mb-2 fw-bold">Are you ready to:</p>
            <ul className="login-list">
              <li>Reduce stress and burnout</li>
              <li>Feel happier and more productive</li>
              <li>Boost your mood and energy levels</li>
            </ul>
            <p className="subheading w-100">
              If yes, you’re in the right place!{" "}
            </p>
          </div>
         
          <div className="col-md-6 col-sm-12">
            <div className="register">
              <form>
                <h2 className="heading">Login</h2>

                <div>
                  <label>
                    <span className="text-danger fw-bold">*</span> Enter
                    registered E-Mail ID/ Mobile No
                  </label>
                  <input
                    type="text"
                    name="InputType"
                    placeholder="Enter registered E-Mail ID/ Mobile No"
                    // defaultValue={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <p className="text-danger">{formErrors?.InputType}</p>
                <p className="text-danger">{ApiError}</p>

               

                <button
                  type="submit"
                  className="button_common w-100 mt-3 mb-3"
                  disabled={isSubmit}
                  onClick={(e) => handleSubmitMobile(e)}
                >
                  Verify via OTP
                </button>
                {loading ? <div className="loader_orange"></div> : <></>}
                <NavLink
                  className="register-text"
                  to={AdminRoute?.Auth?.Register}
                >
                  Let&apos;s Register{" "}
                </NavLink>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      <div className="w-full bg-gradient-to-b from-[#DDE4ED] to-[#FAE2DF] min-h-screen flex items-center justify-center py-[1%] px-[2%] scrollbar-hide">
        <div className="bg-white bg-opacity-75 rounded-[50px] shadow-lg w-full flex py-[3%]">
          <div className="grid grid-cols-2 md:grid-cols-5 gap-4 pl-0 md:pl-[6%]">
            <div className="bg-white rounded-[50px] shadow-lg px-12 col-span-2 py-6">
              <div className="flex justify-center py-4">
                <img src={logo} className="h-auto w-full max-w-[200px]" />
              </div>
              <div className="flex justify-center pb-4">
                <span className="font-extrabold font-poppins-bold text-2xl">BEGIN YOUR WELLNESS JOURNEY</span>
              </div>
              <div className="flex justify-center pb-4">
                <span className="font-bold font-poppins-regular text-xl">Login</span>
              </div>
              <form>
                <ul className="flex items-center mb-2 w-full text-sm font-medium text-gray-900 bg-transparent dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                  <li className="w-full">
                    <div className="flex items-center">
                      <input id="horizontal-list-radio-license" type="radio" value="Client" name="clientType" className="hidden peer" onChange={handleChange} checked={formValues.clientType === "Client"} />
                      <label for="horizontal-list-radio-license" className="inline-flex items-center justify-center w-full py-2 mx-1 text-sm font-medium text-gray-900 bg-[#d9d9d94d] rounded-[20px] cursor-pointer hover:bg-[#F5BD41]  peer-checked:bg-[#F5BD41]  ">
                        Individual
                      </label>
                    </div>
                  </li>
                  <li className="w-full">
                    <div className="flex items-center">
                      <input id="horizontal-list-radio-id" type="radio" value="Cmsemployee" name="clientType" className="hidden peer" onChange={handleChange} checked={formValues.clientType === "Cmsemployee"} />
                      <label for="horizontal-list-radio-id" className="inline-flex items-center justify-center w-full py-2 mx-1 text-sm font-medium text-gray-900 bg-[#d9d9d94d] rounded-[20px] cursor-pointer hover:bg-[#F5BD41]  peer-checked:bg-[#F5BD41]  ">
                        Corporate
                      </label>
                    </div>
                  </li>
                </ul>
                <div className="mt-4">
                  <label className="block text-gray-700 text-xs font-bold mb-2 ml-[2%]" for="username">
                    Enter
                    registered E-Mail ID/ Mobile No
                  </label>
                  <input
                    className="bg-[#d9d9d94d] mb-2 appearance-none border-2 border-[#d9d9d94d] rounded-[30px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none "
                    type="text"
                    name="InputType"
                    autoComplete="off"
                    onChange={handleChange}
                    placeholder="Enter
                  registered E-Mail ID/ Mobile No"
                  />
                </div>
                {otpSent && (<div>
                  <label className="block text-gray-700 text-xs font-bold mb-2 ml-[2%]" for="password">
                    OTP
                  </label>
                  <input
                    className="bg-[#d9d9d94d] mb-2 appearance-none border-2 border-[#d9d9d94d] rounded-[30px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none "
                    type="password"
                    name="otp"
                    autoComplete="off"
                    maxLength="4"
                    placeholder="Enter 4 digit OTP"
                    onChange={(e) => handleChange(e)}
                  />
                </div>)}
                {otpSent && otpSent ? (<>
                  <div className="flex justify-center ">
                    <button
                      type="submit"
                      className="w-40 mt-3 mb-3 font-bold py-2 px-4 rounded-[30px] border-0 bg-[#F5BD41] text-black"
                      onClick={(e) => handleVerifyOtp(e)}
                    >
                      Verify
                    </button>
                  </div>

                </>
                ) : (
                  <div className="flex justify-center ">
                    <button
                      type="submit"
                      className="w-40 mt-3 mb-3 font-bold py-2 px-4 rounded-[30px] border-0 bg-[#F5BD41] text-black"
                      onClick={(e) => handleSubmitMobile(e)}
                    >
                      Send OTP
                    </button>
                  </div>
                )}
              </form>
              <div className="flex justify-center pt-[2%]">
                {remainingTime > 0 ? (
                  <span className="font-bold font-poppins-regular text-md text-[#F5BD41]">
                    Resend Otp in {remainingTime} seconds
                  </span>
                ) : (
                  showResend && (
                    <button className="font-bold text-[#666666] underline " onClick={handleResendOtp}>
                      Resend OTP
                    </button>
                  )
                )}
              </div>
            </div>
            <div className="hidden md:flex flex-col justify-center col-span-3 ml-[12%]">
              <h2 className="text-4xl text-gray-900 font-lusitana font-normal leading-snug mr-[16%] py-[4%]">Experience 24/7 companionship for your mental well-being grow with personalized support.</h2>
              <img src={LoginPageBg} alt="LoginPageBanner" className="h-auto w-full max-w-[400px]" />
            </div>
          </div>
        </div>
      </div>
    </>


  );
};
export default Login;
