import React, { createContext, useState, useEffect } from 'react';

export const PlanContext = createContext();

export const PlanProvider = ({ children }) => {
    const [planDetails, setPlanDetails] = useState(() => {
        const storedPlanDetails = localStorage.getItem('planDetails');
        return storedPlanDetails ? JSON.parse(storedPlanDetails) : null;
    });

    useEffect(() => {
        if (planDetails) {
            localStorage.setItem('planDetails', JSON.stringify(planDetails));
        } else {
            localStorage.removeItem('planDetails'); // Clean up when null
        }
    }, [planDetails]);

    return (
        <PlanContext.Provider value={{ planDetails, setPlanDetails }}>
            {children}
        </PlanContext.Provider>
    );
};
