// App.js
import React, { useState, useEffect } from 'react';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import Button from 'react-bootstrap/Button';
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import AdminRoute from "../../Route/RouteDetails";
import PersonalityTests from "../../Assets/Images/personality-tests.jpg"
import WellBeingTest from "../../Assets/Images/well-being-test.jpg"
import LineChart from "../../Assets/Images/line-chart.jpg";
import RadarChart from '../Common/RadarChart';
import API from '../../Api/Api';
import { getInitialsLetters } from '../Common/Functions';

function Profile() {
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
  const [loading, setLoading] = useState(false);
  const [GraphData, setGraphData] = useState({});
  const [GraphOneData, setGraphOneData] = useState({});
  const [GraphTwoData, setGraphTwoData] = useState({});
  const [GraphThreeData, setGraphThreeData] = useState({});
  const navigate = useNavigate();

  const config = {
    options: {
      chart: {
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1
        }
      },
      labels: [
        "Engagement",
        "Leadership",
        "Burnout"
      ],
    },
    series: [
      {
        name: "OHEP Index",
        data: [
          4.9,
          3.9,
          3.6
        ]
      }
    ]
  };

  let config1 = {
    options: {
      chart: {
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1
        }
      },
      labels: [
        "Engagement",
        "Leadership",
        "Burnout"
      ],
    },
    series: [
      {
        name: "OHEP Index",
        data: [
          4.9,
          3.9,
          3.6
        ]
      }
    ]
  };

  let config2 = {
    options: {
      chart: {
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1
        }
      },
      labels: [
        "Engagement",
        "Leadership",
        "Burnout"
      ],
    },
    series: [
      {
        name: "OHEP Index",
        data: [
          4.9,
          3.9,
          3.6
        ]
      }
    ]
  };

  let state = {
    series: [
      {
        name: "Joy",
        data: [28, 29, 33, 36, 32, 32, 33]
      },
      {
        name: "fodIntake",
        data: [12, 11, 14, 18, 17, 13, 13]
      },
      {
        name: "drive",
        data: [28, 19, 33, 20, 32, 32, 33]
      },
      {
        name: "sleep",
        data: [12, 11, 10, 18, 19, 13, 13]
      },
      {
        name: "stress",
        data: [28, 19, 33, 20, 32, 32, 33]
      },
      {
        name: "activity",
        data: [12, 11, 10, 18, 19, 13, 13]
      }
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ['#77B6EA', '#545454', '#F7E7CE', '#FF8F89', '#49F5FF', '#964B00'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2, // Adjust the line width here
      },
      // title: {
      //   text: 'Average High & Low Temperature',
      //   align: 'left',
      // },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        title: {
          text: 'Month',
        },
      },
      yaxis: {
        title: {
          text: 'Temperature',
        },
        min: 5,
        max: 40,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
      }, setY: -25,
      offsetX: -5
    },
  }


  useEffect(() => {
    setLoading(true)
    try {
      API.Survey.AnswerSurvey()
        .then((response) => {
          if (response.data.status === 1) {
            if (response?.data.graph1.length) {
              config.options.labels = response?.data?.graph1[0]?.keys
              config.series[0].data = response?.data?.graph1[0]?.values
              setGraphOneData(config)
            }
            if (response?.data.graph2.length) {
              config1.options.labels = Object.keys(response?.data?.graph2[0])
              config1.series[0].data = Object.values(response?.data?.graph2[0])
              setGraphTwoData(config1)
            }
            if (response.data.graph3.length) {
              state.series = response?.data?.graph3[0]
              // config2.series[0].data = response.data.graph3[0].values
              setGraphThreeData(state)
            }
            setGraphData(response?.data);
          }
          setLoading(false)
        });
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  }, [])

  const handleGraphValidation = (graph) => {
    API.Survey.GetTimit()
      .then((response) => {
        if (!response?.data?.personality) {
          if (graph === "PersonalityTests") {
            navigate(`../${AdminRoute?.Common?.PersonalityTests}`)
          }
        }
        if (!response.data.wellbeing) {
          if (graph === "WellBeingTest") {
            navigate(`../${AdminRoute?.Common?.WllbeingTest}`)
          }
        }
      }).catch((err) => {
        console.log(err)
      })
  }


  //jsx
  return (
    <>
      {/* <div className="d-flex h-dashboard profile">
        <Sidebar />
        <div className='toggleIcon'>
          <div className="container-fluid">

            <div className="row">
              <div className="nav-wrap">
                <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                  <h2 className='heading  mt-1 mb-1'>My Profile</h2>
                  <Header />
                </nav>
              </div>

              <div className="col-12 mb-2 profile-v-height">
                <div className='row'>
                  <div className="col-md-8 b-0">
                    <div className="card-body payment-card-body">
                      <div className="row">
                        <h3 className="secheading mt-3 mb-2">Personal Details</h3>
                        <p className='mb-3 text-muted'>Below are your profile details</p>

                        <div className='col-12'>
                          <div className='profile-image-box d-flex justify-content-center align-items-center mb-3'>
                            {UserData?.userdata?.image ?
                              <img src={UserData?.userdata?.image} alt="profile"></img>
                              :
                              <p className='default-text text-center'>{getInitialsLetters(UserData?.userdata?.name, UserData?.userdata?.last_name)}</p>
                            }
                          </div>
                        </div>
                        <div className="col-md-6 left-profile-div pl-3">

                          <div className="col-sm-12 mb-3">
                            <span className="">First Name: </span><span className="col-sm-9 fw-bold"> {UserData?.userdata?.name} </span>
                          </div>
                          <div className="col-sm-12 mb-3">
                            <span className="">Last Name: </span><span className="col-sm-9 fw-bold"> {UserData?.userdata?.last_name} </span>
                          </div>

                          <div className="col-sm-12 mb-3">
                            <span className="">Date of birth: </span><span className="col-sm-9 fw-bold">{moment(UserData?.userdata?.dob).format("LL")}</span>
                          </div>
                        </div>

                        <div className="col-md-6 right-profile-div">
                          <div className="row">
                            <div className="col-sm-12 mb-3">
                              <span className="">E-Mail Id:  </span><span className="col-sm-y fw-bold"> {UserData?.userdata?.email}</span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12 mb-3">
                              <span className="">Mobile No.: </span><span className="col-sm-9 fw-bold"> {UserData?.userdata?.phone} </span>
                            </div>
                          </div>

                          <div className="row d-flex justify-content-end">
                            <div className="col-sm-12 w-50">
                              <Button type="submit" className="transparent-border-btn mb-0.">
                                <NavLink to={`${AdminRoute?.Common?.UserProfile}`}> Edit Profile </NavLink>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <h3 className="secheading mt-3 mb-3">Wellness Map</h3>
                    {loading ?
                      <div className="container_loader my-5"></div>
                      :
                      <div className="wrapper-play mb-0 mb-md-4">
                        <div className="box">
                          <div className="card-body" onClick={() => handleGraphValidation("PersonalityTests")}>
                            {GraphData?.graph1?.length && Object.keys(GraphData?.graph1[0]).length !== 0 ?
                              <RadarChart options={GraphOneData.options} series={GraphOneData.series} type="radar" />
                              :
                              <div className="playground-img-div bg-grey mb-3">
                                <span className="img">
                                  <img src={PersonalityTests} alt="PersonalityTests" />
                                </span>
                              </div>
                            }
                            <div className="support-div-text">
                              <h3 className="card-heading text-left">Personality Tests</h3>
                              <p className="card-text mb-2 text-muted published-date"> Published Date: 01 Sept, 2023</p>
                            </div>
                          </div>
                        </div>
                        <div className="box">
                          <div className="card-body" onClick={() => handleGraphValidation("WellBeingTest")}>
                            {GraphData?.graph2?.length && Object.keys(GraphTwoData)?.length !== 0 ?
                              <RadarChart options={GraphTwoData?.options} series={GraphTwoData?.series} type="radar" />
                              :
                              <div className="playground-img-div bg-grey mb-3">
                                <span className="img">
                                  <img src={WellBeingTest} alt="WellBeingTest" />
                                </span>
                              </div>
                            }
                            <div className="support-div-text">
                              <h3 className="card-heading text-left">Well being Test</h3>
                              <p className="card-text mb-2 text-muted published-date"> Published Date: 01 Sept, 2023</p>
                            </div>
                          </div>
                        </div>
                        <div className="box">
                          <div className="card-body">
                            {GraphData?.graph3?.length && Object.keys(GraphThreeData).length !== 0 ?
                              <RadarChart options={GraphThreeData?.options} series={GraphThreeData?.series} type="line" />
                              :
                              <div className="playground-img-div bg-grey mb-3">
                                <span className="img">
                                  <img src={LineChart} alt="LineChart" />
                                </span>
                              </div>
                            }
                            <div className="support-div-text">
                              <h3 className="card-heading text-left">Line Chart</h3>
                              <p className="card-text mb-2 text-muted published-date"> Published Date: 01 Sept, 2023</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div> */}
      <div className="flex flex-col max-h-screen max-w-screen bg-white mx-0 w-full">
        <div className="flex-grow bg-white m-0 w-full p-0">
          <Header />
          <Sidebar />
          <div className="flex-grow flex flex-col bg-white md:flex-col lg:flex-col md:ml-[8%] h-full md:mr-4 md:my-8">
            <h2 className='mt-1 mb-3'>My Profile</h2>
            <div className="w-full mb-2">
              <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-2/3">
                  <div className="p-4 bg-white shadow rounded">
                    <h3 className="text-lg font-semibold mt-3 mb-2">Personal Details</h3>
                    <p className="mb-3 text-gray-500">Below are your profile details</p>
                    <div className="w-full flex justify-center mb-3">
                      {UserData?.userdata?.image ? (
                        <img
                          src={UserData?.userdata?.image}
                          alt="profile"
                          className="rounded-full w-24 h-24 object-cover"
                        />
                      ) : (
                        <p className="text-center text-xl font-bold bg-gray-200 rounded-full w-24 h-24 flex items-center justify-center">
                          {getInitialsLetters(UserData?.userdata?.name, UserData?.userdata?.last_name)}
                        </p>
                      )}
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <div className="mb-3">
                          <span className="font-medium">First Name: </span>
                          <span className="font-bold">{UserData?.userdata?.name}</span>
                        </div>
                        <div className="mb-3">
                          <span className="font-medium">Last Name: </span>
                          <span className="font-bold">{UserData?.userdata?.last_name}</span>
                        </div>
                        <div className="mb-3">
                          <span className="font-medium">Date of Birth: </span>
                          <span className="font-bold">
                            {moment(UserData?.userdata?.dob).format("LL")}
                          </span>
                        </div>
                      </div>

                      <div>
                        <div className="mb-3">
                          <span className="font-medium">E-Mail ID: </span>
                          <span className="font-bold">{UserData?.userdata?.email}</span>
                        </div>
                        <div className="mb-3">
                          <span className="font-medium">Mobile No.: </span>
                          <span className="font-bold">{UserData?.userdata?.phone}</span>
                        </div>
                        <div className="flex justify-end">
                          <button className="mt-3 h-8 bg-[#F5BD41] border border-[#F5BD41] text-black py-2 px-4 rounded-lg font-semibold align-middle text-center text-xs">
                            {/* <NavLink
                              to={`${AdminRoute?.Common?.UserProfile}`}
                              className="no-underline text-black"
                            > */}
                              Edit Profile
                            {/* </NavLink> */}
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full mt-6">
                <h3 className="text-lg font-semibold mt-3 mb-3">Wellness Map</h3>
                {loading ? (
                  <div className="my-5 flex justify-center">
                    <div className="loader"></div>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {[
                      {
                        title: "Personality Tests",
                        image: PersonalityTests,
                        chart: GraphData?.graph1,
                        graphData: GraphOneData,
                        handler: () => handleGraphValidation("PersonalityTests"),
                      },
                      {
                        title: "Well-being Test",
                        image: WellBeingTest,
                        chart: GraphData?.graph2,
                        graphData: GraphTwoData,
                        handler: () => handleGraphValidation("WellBeingTest"),
                      },
                      {
                        title: "Line Chart",
                        image: LineChart,
                        chart: GraphData?.graph3,
                        graphData: GraphThreeData,
                      },
                    ].map((item, idx) => (
                      <div
                        key={idx}
                        className="bg-white shadow rounded p-4 cursor-pointer"
                        // onClick={item.handler}
                      >
                        {item.chart?.length && Object.keys(item.graphData?.options || {}).length !== 0 ? (
                          <RadarChart
                            options={item.graphData.options}
                            series={item.graphData.series}
                            type="radar"
                          />
                        ) : (
                          <div className="bg-gray-200 flex justify-center items-center mb-3">
                            <img src={item.image} alt={item.title} className="h-32" />
                          </div>
                        )}
                        <div>
                          <h3 className="text-lg font-semibold">{item.title}</h3>
                          <p className="text-gray-500 text-sm">Published Date: 01 Sept, 2023</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}

export default Profile;
