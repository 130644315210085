import React from 'react'

const JoinGroupCard = ({data, onClick}) => {
    console.log('data',data);
    return (
        <div class="max-w-sm rounded overflow-hidden shadow-md">
            {/* <img class="w-full" src="/img/card-top.jpg" alt="Sunset in the mountains" /> */}
                <div class="px-6 py-2">
                    <div class="font-bold text-xl mb-2">{data.name}</div>
                    <p class="text-gray-700 text-base">
                        {data.description}
                    </p>
                </div>
        <div className='flex justify-center items-center my-2'>
        <button className='bg-[#FFB81D] px-4 py-2 text-black text-sm rounded-full shadow-md font-bold' onClick={onClick}>Join Group</button>
        </div>
        </div>
    )
}

export default JoinGroupCard