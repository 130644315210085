import React from 'react';
import { FaCheckCircle } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

const PlanCard = ({ planName, amount, onClick, features }) => {

    const cardClass = planName === 'Half-Yearly'
        ? 'from-[#FE5310BA] to-[#F8D27F26]'
        : planName === 'Quarterly'
            ? 'from-[#10B2FEBA] to-[#F8D27F26]'
            : planName === 'Monthly'
                ? 'from-[#10FEF2BA] to-[#F8D27F26]'
                : '';

    return (
        <div className="cursor-pointer bg-[#FFFFFF] flex justify-center items-center rounded-3xl hover:scale-105" onClick={onClick} style={{ border: '1px solid #C4C4C4' }}>
            <div className={`rounded-3xl shadow-lg  transform transition overflow-hidden`} >
                <div className={`h-28 w-full bg-gradient-to-r ${cardClass} px-8 py-4 flex justify-center items-center`} >
                    <div className='flex justify-center items-center px-6'>
                        <div class="p-2.5 bg-[#F5F6FB] flex rounded-lg">
                            <div class="w-8 h-8 rounded-full overflow-hidden">
                                <div class="w-full h-full bg-gradient-to-l from-[#F5BD41] to-[#F8D27F6B] transform rotate-180"></div>
                            </div>
                        </div>
                        <div className='pl-3'>
                            <p className="text-md text-gray-600 font-semibold mb-0">For Individuals</p>
                            <p className="text-2xl font-semibold text-gray-900 mb-0">{planName}</p>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center items-center flex-col py-2'>
                    <div className="text-[50px] font-bold text-gray-800 mt-2 mb-1">
                        <div className='flex justify-center items-center'>
                            <FaRupeeSign />{amount}<span className='text-sm text-gray-500 mt-3'>/monthly</span>
                        </div>
                    </div>
                    <div className='flex justify-center item-center flex-col py-2 px-8'>
                        <p className='text-gray-900 text-md font-semibold mb-2'>What's included</p>
                        {Object.entries(features).map(([key, value], index) => (
                            <div key={index} className="flex flex-col">
                                <div className="flex justify-start items-center">
                                    <FaCheckCircle className="mr-2" color="#28a745" size={15} />
                                    <p className="text-sm text-gray-500 mb-0">{value}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='flex justify-center items-center mt-4 mb-4'>
                        <button className='bg-[#FFB81D] px-8 py-2 text-black text-sm rounded-lg shadow-lg '>Get Started </button>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default PlanCard;
