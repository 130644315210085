// App.js
import React from "react";
import Sidebar from "../Common/Sidebar";

import AitherapistCore from "./AitherapistCore";
function Dashboard() {
	return (
		<div className="d-flex h-dashboard">
			<Sidebar />
			<AitherapistCore />
		</div>
	);
}

export default Dashboard;
