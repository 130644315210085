import React from 'react';
import ReactApexChart from "react-apexcharts";



const RadarChart = ({ options, series, type }) => {

    return (
        <div style={{ height: '200px' }}>
            <ReactApexChart
                options={options}
                series={series}
                type={type}
                height={type === 'line' ? "200" : '250'}
                width={300}
            />
        </div>
    )
}

export default RadarChart