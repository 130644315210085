import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import useRazorpay from "react-razorpay";
import PaymentSucces from "../Common/modal/PaymentSucessFull";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import PlanCard from "../PlanCard";
import PricePageBG from "../../Assets/Images/PricepageBG.jpg";
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { PlanContext } from "../../ContextApi/PlanContext";

function PlanModal({ isOpen, onClose }) {
    const [PlanList, setPlanList] = useState([]);
    const [loading, setLoading] = useState(false);
    const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
    const [showPopup, setShowPopUp] = useState(false);
    const navigate = useNavigate();
    const [Razorpay] = useRazorpay();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const PlanUpgrade = queryParams.get("plan");
    const { planDetails, setPlanDetails } = useContext(PlanContext);

    // const Logoutfun = () => {
    //     localStorage.removeItem("EmailId");
    //     localStorage.removeItem("AuthTokenClient");
    //     localStorage.removeItem("ClientUserData");
    //     navigate(`../${AdminRoute?.Auth?.Login}`);
    // };

    useEffect(() => {
        getPlanList();
        MyPlanlfeatures();
    }, []);

    const MyPlanlfeatures = () => {
        setLoading(true);
        try {
            API.Payment.MyPlanlfeatures({
                data: UserData.userdata.role
            }).then((response) => {
                if (response.status === 200) {
                    console.log('planFeatures',response)
                    setPlanDetails(response.data.plandetails);
                }
            });
        } catch (error) {
            false(false);
            console.log(error);
        }
    };
    //api call for plan list
    const getPlanList = () => {
        setLoading(true);
        try {
            API.Auth.PlanPlanListAPI({}).then((response) => {
                if (response?.data?.status === 1) {
                    setPlanList(response?.data?.data);
                }
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const handlePlanCheckPayment = (plan_id) => {
        API.Payment.ChackPlanAllow({
            data: {
                name: UserData?.userdata?.name,
                created_by: UserData?.userdata?._id,
                amount: plan_id?.amount,
                plan_id: plan_id?._id,
            },
        })
            .then((response) => {
                if (response?.data?.status == 1) {
                    handlePlan(plan_id);
                } else {
                    toast.error(response?.data?.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // select plan api call
    const handlePlan = (plan_id) => {
        if (plan_id.name === "") {
            handlePlanSave(plan_id);
        } else {
            // API.Auth.SelectThemeById({
            //   data: {
            //     plan_id: plan_id?._id,
            //   },
            //   UserId: UserData?.userdata?._id,
            // })
            //   .then((response) => {
            //     if (response.data.status) {
            //       // navigate(`../${AdminRoute?.Common?.Theam}`);
            //     }
            //   })
            //   .catch((error) => {
            //     console.log(error);
            //   });

            // get rezorpay orderid id
            API.Payment.RezorPayGetId({
                data: {
                    name: UserData?.userdata?.name,
                    created_by: UserData?.userdata?._id,
                    amount: plan_id?.amount,
                    plan_id: plan_id?._id,
                },
            })
                .then((response) => {
                    if (response?.data?.message === "successfully") {
                        RezorPaySdk(response?.data?.data, plan_id, response?.data?.key_id);
                        // showRazorpay(response.data.data.id, plan_id.amount, plan_id,)
                    } else {
                        toast.error(response?.data?.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const RezorPaySdk = (orderID, plan_id, appKey) => {
        // let Amount = Math.round(amount * 1000)
        const options = {
            // key: "rzp_test_nYt1RNLg2H6FzV", // Enter the Key ID generated from the Dashboard
            key: appKey,
            amount: orderID?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: orderID?.currency,
            name: "RYT",
            description: orderID?.notes?.description
                ? orderID?.notes?.description
                : "Test",
            image: "https://example.com/your_logo",
            order_id: orderID.id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
            handler: function (response) {
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
                console.log("success");
                handleRezorpayResponce(
                    response,
                    orderID.id,
                    orderID.amount,
                    "finish",
                    plan_id
                );
                handlePlanSave(plan_id);
            },
            prefill: {
                name: UserData?.userdata?.name,
                email: UserData?.userdata?.email,
                contact: UserData?.userdata?.phone,
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#3399cc",
            },
        };
        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            handleRezorpayResponce(
                response,
                orderID,
                orderID?.amount,
                "decline",
                plan_id
            );
        });
        rzp1.open();
    };

    const handleRezorpayResponce = (
        response,
        orderID,
        amount,
        status,
        plan_id
    ) => {
        API.Payment.Paymentupdate({
            data: {
                order_id: orderID,
                name: UserData?.userdata?.name,
                created_by: UserData?.userdata?._id,
                amount: Math.floor(amount / 100),
                responce_obj: response,
                order_status: status,
                paymentfeatures: plan_id?.name,
            },
        })
            .then((response) => {
                if (response.data.status === 1) {
                    setShowPopUp(true);
                    // setSucesspayment(plan_id);
                    if (!PlanUpgrade) {
                        // handleStep(plan_id);
                    }
                    // if (plan_id.name === "BASIC Plan") {
                    //     setTimeout(() => {
                    //         setShowPopUp(false);
                    //         if (PlanUpgrade) {
                    //             navigate(`../${AdminRoute?.Common.Dashboard}`);
                    //         } else {
                    //             navigate(`../${AdminRoute?.Common?.Theam}?plan=2`);
                    //         }
                    //     }, 1000);
                    // } else if (plan_id.name === "PRO Plan") {
                    //     setTimeout(() => {
                    //         setShowPopUp(false);
                    //         if (PlanUpgrade) {
                    //             navigate(`../${AdminRoute?.Common.Dashboard}`);
                    //         } else {
                    //             navigate(`../${AdminRoute?.Common?.Theam}?plan=3`);
                    //         }
                    //     }, 1000);
                    // } else {
                    //     setTimeout(() => {
                    //         setShowPopUp(false);
                    //         if (PlanUpgrade) {
                    //             navigate(`../${AdminRoute?.Common.Dashboard}`);
                    //         } else {
                    //             navigate(`../${AdminRoute?.Common?.Theam}?plan=2`);
                    //         }
                    //     }, 1000);
                    // }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // const setSucesspayment = (plan_id) => {
    //     API.Auth.SelectThemeById({
    //         data: {
    //             plan_id: plan_id?._id,
    //         },
    //         UserId: UserData?.userdata?._id,
    //     })
    //         .then((response) => {
    //             if (response.data.status) {
    //                 // navigate(`../${AdminRoute?.Common?.Theam}`);
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // };

    const handlePlanSave = (plan_id) => {
        console.log('plan_id', plan_id);
        API.Payment.Adduserplan({
            data: {
                planid: plan_id,
                UserId: UserData?.userdata?._id,
            },
        })
            .then((response) => {
                console.log(response?.data);
                if (response?.data?.status === 1) {
                    MyPlanlfeatures();
                    onClose();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };



    // const handleStep = (plan_id) => {
    //     try {
    //         API.Auth.UpdateProfileAPI({
    //             data: {
    //                 step: plan_id?.name === "Pay Per Feature" ? 6 : 5,
    //             },
    //             UserId: UserData?.userdata?._id,
    //         }).then((response) => {
    //             console.log(response);
    //         });
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    if (!isOpen) return null;

    //jsx
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="rounded-lg max-w-[90%] relative overflow-hidden flex flex-col py-8 px-8 bg-white" style={{
                backgroundImage: `url(${PricePageBG})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}>
                <button
                    className="absolute top-2 right-2 bg-white rounded-full p-1"
                    onClick={onClose}
                >
                    <IoMdClose size={25} color="#000" />
                </button>
                {planDetails === null || !planDetails.name === "Trial" &&
                    <div className="flex justify-center flex-col items-center mt-2">
                        {PlanList?.length &&
                            PlanList.map((plan, i) => {
                                if (plan.name === "Trial") {
                                    return (
                                        <div key={i} className="flex justify-center items-center mt-2">
                                            <button
                                                className="bg-[#FFB81D] px-8 py-2 text-black text-sm rounded-lg shadow-lg font-bold flex justify-center items-center hover:scale-105"
                                                onClick={() => handlePlanSave(plan._id)}
                                            >
                                                Continue with Free Trial
                                                <FaLongArrowAltRight className="ml-2" size={22} />
                                            </button>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        <span className="text-gray-400 text-4xl mt-4 font-semibold">OR</span>
                    </div>}

                <div className="flex-1 flex w-full h-full mt-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                        {PlanList?.length &&
                            PlanList.filter(plan => plan.name !== "Trial").map((val, i) => {
                                return (
                                    <div key={i} className="flex justify-center items-center mt-2">
                                        <PlanCard
                                            planName={val?.name}
                                            amount={val?.amount}
                                            features={val?.includedPages}
                                            onClick={() => handlePlanCheckPayment(val)}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>



    );
}
export default PlanModal;
