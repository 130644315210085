import React from "react";

function Footer() {
  return (
    <div className="row mt-1">
    <div className="col-12">
      <footer className="footer emergency_contact rounded-pill">
        <p className="contact-text m-0">Emergency Contact: 8122 369 4242</p>
        <p className="contact-text m-0">All rights reserved by RYTLife</p>
      </footer>
    </div>
    </div>
  );
}

export default Footer;
