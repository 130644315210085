/* eslint-disable */
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Button from "react-bootstrap/Button";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import moment from "moment";
import { isBeforeOrAfterCurrent, combineDateTime } from "../Common/Functions";
import logo from "../../Assets/Images/Login/ryteLife-full.png";
// import "../../Assets/Css/Style.css"

const Appointment_scheduler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const AppointmentId = queryParams.get("id");
  const check = queryParams.get("key");
  const currentDate = new Date();
  // const tomorrowDate = new Date(currentDate);
  // tomorrowDate.setDate(currentDate.getDate() + 1);
  const [value, onChange] = useState(currentDate.toString());
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
  const [therapistData, setTherapishData] = useState({});
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    getTherapistDetails();
  }, [value]);

  // const getTherapistDetails = () => {
  //   setLoading(true);
  //   setError("");
  //   setSelectedSlot({});
  //   API.Therapist.getTherpistDetails({
  //     date: moment(value).format("YYYY-MM-DD"),
  //   })
  //     .then((response) => {
  //       if (response?.data?.status === 1) {
  //         console.log(response?.data?.data_all);
  //         setTherapishData(response?.data?.data_all[0]);
  //         setTimeSlots(response?.data?.data_all[0]?.theropist_calender?.time);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //     });
  // };

  const handleSlotClick = (slot) => {
    if (Object.values(slot)[0]) {
      setSelectedSlot(slot);
    }
  };

  const handleNext = () => {
    setSubmit(true);
    if (AppointmentId) {
      API.Session.DeleteSession({
        id: AppointmentId,
      })
        .then((response) => {
          if (response?.data?.status === 1) {
            handelNextApi();
            setSubmit(false);
          }
        })
        .catch((error) => {
          setSubmit(false);
          console.log(error);
        });
    } else {
      setSubmit(false);
      handelNextApi();
    }
  };

  const handelNextApi = () => {
    if (Object.keys(selectedSlot)[0]) {
      const inputDateTime = combineDateTime(
        moment(value).format("YYYY-MM-DD"),
        Object.keys(selectedSlot)[0]
      );
      let ISTtime = moment(inputDateTime).format("YYYY-MM-DDTHH:mm:ss");
      ISTtime = ISTtime.toLocaleString();
      setError("");
      API.Session.CreateSession({
        data: {
          clientId: UserData?.userdata?._id,
          theropistId: therapistData?._id,
          // date: moment(value).format("YYYY-MM-DD"),
          // timestart: Object.keys(selectedSlot)[0],
          date: ISTtime + "+00:00",
          timestart: Object.keys(selectedSlot)[0],
          timeend: "11PM",
        },
      })
        .then((response) => {
          // console.log(response?.data?.status);
          if (response?.data?.status === 1) {
            setSubmit(false);
            if (AppointmentId || check) {
              navigate(`../${AdminRoute?.Common?.Dashboard}`);
            } else {
              navigate(`../${AdminRoute?.Common?.Join_Group}`);
            }
          } else {
            setError(response?.data?.message);
          }
        })
        .catch((error) => {
          // toast.error(response?.damessage)
          console.log(error);
        });
    } else {
      setSubmit(false);
      setError("Select time slot.");
    }
  };

  const minDate = new Date(); // Today's date

  return (
    // <div className="main-container dashboard therapist appointmentscheduler pb-0">
    //   <div className="row d-flex justify-content-center inner my-0">
    //     <div className="col-md-12">
    //       <Navbar className="top-bar">
    //         <span className="backbtn" onClick={() => navigate(-1)}>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="22"
    //             height="22"
    //             fill="currentColor"
    //             className="bi bi-chevron-left"
    //             viewBox="0 0 16 16"
    //           >
    //             <path
    //               fillRule="evenodd"
    //               d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
    //             />
    //           </svg>
    //         </span>
    //         <Nav className="mr-auto">
    //           <span className="logo-div">
    //             <NavLink to="/">
    //               <img src="/images/logo.png" className="logo" alt="logo" />
    //             </NavLink>
    //           </span>
    //         </Nav>
    //       </Navbar>
    //     </div>
    //     <div className="col-md-12">
    //       <h2 className="heading mb-2 mt-4">Start Feeling Better Today!</h2>
    //       <h4 className="secheading mb-4">
    //         Connect with Caring Experts Ready to Guide You{" "}
    //       </h4>
    //     </div>
    //     <div className="row px-0">
    //       <div className="col-lg-3 mb-4 col-md-6 therapist-doctor">
    //         <div className="inner-div image-div">
    //           <div className="d-flex align-items-top">
    //             <div className="doctor-img d-flex align-items-center justify-content-center">
    //               <img
    //                 className="d-block"
    //                 src={
    //                   therapistData?.image
    //                     ? therapistData?.image
    //                     : "https://as2.ftcdn.net/v2/jpg/00/65/77/27/1000_F_65772719_A1UV5kLi5nCEWI0BNLLiFaBPEkUbv5Fv.jpg"
    //                 }
    //                 alt={therapistData?.image}
    //               />
    //             </div>
    //             <div>
    //               <h3 className="ms-3 text-white mt-4 mt-md-0">
    //                 {therapistData?.fullname}
    //               </h3>
    //               {/* <h5 className="ms-3 text-white doctor-degree mt-3">MBBS in</h5> */}
    //             </div>
    //           </div>
    //           <div className="fst-italic mt-2 mt-md-5">
    //             <p>
    //               Experience :{" "}
    //               <span className="fw-normal">
    //                 {therapistData?.experience}Years
    //               </span>
    //             </p>
    //             <p>
    //               Experties :{" "}
    //               <span className="fw-normal">{therapistData?.experties}</span>
    //             </p>
    //             <p>
    //               Qote: <span className="fw-normal">{therapistData?.qote}</span>
    //             </p>
    //           </div>
    //         </div>
    //       </div>

    //       <div className="col-lg-4 mb-4 col-md-6">
    //         <div className="calendar inner-div">
    //           <Calendar onChange={onChange} value={value} minDate={minDate} />
    //         </div>
    //       </div>

    //       <div className="col-lg-5 mb-4 col-md-12">
    //         <div className="slot-container inner-div">
    //           <div className="time-slot-wrap">
    //             {!loading ? (
    //               <>
    //                 <p>Tap on the time slot that best suits you</p>
    //                 {timeSlots?.length &&
    //                   timeSlots?.map((slot, index) => {
    //                     return (
    //                       <>
    //                         {index !== 0 ? (
    //                           <>
    //                             {Object?.values(slot)[0] &&
    //                             isBeforeOrAfterCurrent(
    //                               value,
    //                               Object?.keys(slot)[0]
    //                             ) ? (
    //                               <div
    //                                 key={index}
    //                                 className={
    //                                   !Object?.values(slot)[0]
    //                                     ? "slot selected"
    //                                     : Object?.keys(selectedSlot)[0] ===
    //                                       Object?.keys(slot)[0]
    //                                     ? "slot perticular"
    //                                     : "slot"
    //                                 }
    //                                 onClick={() => handleSlotClick(slot)}
    //                               >
    //                                 {Object.keys(slot)}
    //                               </div>
    //                             ) : (
    //                               <div key={index} className={"slot selected"}>
    //                                 {Object?.keys(slot)}
    //                               </div>
    //                             )}
    //                           </>
    //                         ) : null}
    //                       </>
    //                     );
    //                   })}
    //               </>
    //             ) : (
    //               <div className="container_loader"></div>
    //             )}
    //           </div>
    //         </div>

    //         <p className="text-danger mt-1">{error}</p>
    //         {Object.keys(therapistData).length &&
    //         timeSlots?.length === 0 &&
    //         !loading ? (
    //           <span className="text-danger">Slots are not available</span>
    //         ) : Object?.keys(therapistData)?.length === 0 ? (
    //           <span className="text-danger">
    //             Please select therapist for appointment.
    //           </span>
    //         ) : null}
    //       </div>
    //       <div className="col-md-12 d-flex skip">
    //         <div className="mt-4 mb-4 skip-text">
    //           {" "}
    //           <NavLink to={`../${AdminRoute.Common.Dashboard}`}>Skip </NavLink>
    //         </div>
    //         <div>
    //           <Button
    //             type="submit"
    //             className="button_common mt-4 ml-4 next-btn"
    //             disabled={submit}
    //             onClick={() => handleNext()}
    //           >
    //             Next
    //           </Button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* ... (your existing code) */}
    // </div>

    <div className="bg-gray-100 min-h-screen py-8">
      <div className="container mx-auto px-4">
        {/* Navbar */}
        <div className="bg-white shadow-md rounded-lg p-4 flex justify-between items-center mb-6">
          <button
            onClick={() => navigate(-1)}
            className="text-gray-700 hover:text-gray-900 flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              className="bi bi-chevron-left mr-2"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
            Back
          </button>
          <NavLink to="/">
            <img src={logo} alt="logo" className="h-8" />
          </NavLink>
        </div>

        {/* Heading */}
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold text-gray-800">Start Feeling Better Today!</h2>
          <h4 className="text-lg text-gray-600 mt-2">
            Connect with Caring Experts Ready to Guide You
          </h4>
        </div>

        {/* Cards Section */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Therapist Card */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <div className="flex items-center">
              <div className="w-16 h-16 rounded-full overflow-hidden flex-shrink-0">
                <img
                  src={
                    therapistData?.image ??
                    "https://as2.ftcdn.net/v2/jpg/00/65/77/27/1000_F_65772719_A1UV5kLi5nCEWI0BNLLiFaBPEkUbv5Fv.jpg"
                  }
                  alt={therapistData?.fullname}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="ml-4">
                <h3 className="text-xl font-semibold text-gray-800">{therapistData?.fullname}</h3>
                <p className="text-sm text-gray-600">Experience: {therapistData?.experience} Years</p>
              </div>
            </div>
            <div className="mt-4 text-gray-600">
              <p>
                <span className="font-semibold">Expertise:</span> {therapistData?.experties}
              </p>
              <p>
                <span className="font-semibold">Quote:</span> {therapistData?.qote}
              </p>
            </div>
          </div>

          {/* Calendar Card */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Choose a Date</h3>
            <Calendar onChange={onChange} value={value} minDate={minDate} />
          </div>

          {/* Time Slots Card */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Available Slots</h3>
            {!loading ? (
              <>
                <p className="text-gray-700 mb-2">Tap on the time slot that suits you:</p>
                <div className="flex flex-wrap gap-2">
                  {timeSlots?.map((slot, index) => (
                    <button
                      key={index}
                      className={`px-4 py-2 rounded ${Object.keys(selectedSlot)[0] === Object.keys(slot)[0]
                          ? "bg-blue-500 text-white"
                          : "bg-gray-200 text-gray-700"
                        }`}
                      onClick={() => handleSlotClick(slot)}
                    >
                      {Object.keys(slot)}
                    </button>
                  ))}
                </div>
              </>
            ) : (
              <div className="loader"></div>
            )}
            <p className="text-red-500 mt-2">{error}</p>
            {Object.keys(therapistData).length && !timeSlots?.length && !loading && (
              <p className="text-red-500">Slots are not available</p>
            )}
          </div>
        </div>

        {/* Footer */}
        <div className="flex justify-between items-center mt-8">
          <NavLink to={`../${AdminRoute.Common.Dashboard}`} className="text-blue-600 hover:underline">
            Skip
          </NavLink>
          <button
            type="submit"
            className="bg-blue-500 text-white px-6 py-2 rounded disabled:opacity-50"
            disabled={submit}
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      </div>
    </div>

  );
};

export default Appointment_scheduler;
